import * as types from '../mutation-types';

export default {
    namespaced: true,
    state: {
        orders: [],
    },
    actions: {
        placeOrder({ dispatch }, { fullName, phone, email, destination, }) {
            return window.axios
                .post('/orders/place', {
                    fullName,
                    phone,
                    email,
                    destination,
                    address: "address",
                    transCode: "trans_code"
                })
                .then((response) => {
                    dispatch('cart/getCart', null, { root: true })
                    return response.data;
                });
        },
        fetchAllOrders({ commit }) {
            return window.axios.get('/orders').then((response) => {
                commit(types.ORDERS, response.data);
                return response.data;
            }).catch(err => {
            })
        },
        fetchOrderById({ commit }, { id }) {
            return window.axios.get(`/orders/${id}`).then((response) => {
                return response.data;
            });
        },
    },
    mutations: {
        [types.ORDERS](state, orders) {
            state.orders = orders;
        },
    },
};

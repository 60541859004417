import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		albums: [],
	},
	actions: {
		fetchAllAlbums({ commit, state }) {
			return state.albums.length
				? state.albums
				: window.axios.get('/albums').then((response) => {
						commit(types.ALBUMS, response.data);
						return response.data;
				});
		},
		fetchAlbumsBySlug({ commit }, { slug }) {
			return window.axios.get(`/albums/${slug}`).then((response) => {
				return response.data;
			});
		},
	},
	mutations: {
		[types.ALBUMS](state, albums) {
			state.albums = albums;
		},
	},
	getters: {
		getAlbumsBySlug: (state) => (slug) =>
			state.albums.find((e) => e.slug === slug),
	},
};

import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import axios from 'axios';
import store from './store';
import VueMeta from 'vue-meta';
import i18n from './i18n';
import { BASE_URL } from './constants';

const $eventBus = new Vue();
Vue.prototype.$eventBus = $eventBus;

window.axios = axios.create({
	baseURL: BASE_URL,
});
window.axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 401) {
			store.dispatch('login/logout');
			store.commit('snackbar/showErrorSnackbar', i18n.t('messages.session-expired'));
			router.push({ name: 'login' });
			return Promise.reject(error);
		} else {
			throw error;
		}
	},
);

let fromEdunix = false;
router.beforeEach((to, from, next) => {
	if (Object.hasOwnProperty.call(to.query, 'desktopApp')) {
		fromEdunix = true;
		Vue.prototype.$fromEdunix = true;
	}
	if (!fromEdunix) {
		Vue.prototype.$fromEdunix = false;
	}
	if (
		from.query.desktopApp &&
		['home', 'newsView', 'allNews', 'allServices', 'aboutUs'].includes(to.name)
	) {
		next({ name: 'edunix' });
	} else next();
});

// Optionally install the BootstrapVue icon components plugin
Vue.use(VueMeta);

Vue.config.productionTip = false;

window.axios.defaults.headers['accept-language'] = 'ar';

new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount('#app');

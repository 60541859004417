import * as types from "../mutation-types"

export default {
    namespaced: true,
    state: {
        services: []
    },
    actions: {
        fetchAllServices({ commit }) {
            return window.axios.get('/services').then(response => {
                commit(types.SERVICES, response.data)
                return response.data
            })
        },
        fetchServicesBySlug({ commit }, { slug }) {
            return window.axios.get(`/services/${slug}`).then(response => {
                return response.data
            })
        }
    },
    mutations: {
        [types.SERVICES](state, services) {
            state.services = services
        }
    },
    getters: {
        getServicesBySlug: state => slug => state.services.find(e => e.slug === slug)
    }
}
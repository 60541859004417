<template>
	<div id="app">
		<TheNavbar
			v-if="!$fromEdunix"
			class="mb-6"></TheNavbar>
		<router-view
			class="page"
			v-if="load"
			:class="$fromEdunix ? 'pt-4' : ''"></router-view>
		<LoadingComponent v-if="!load"></LoadingComponent>
		<TheFooter v-if="!$fromEdunix && load"></TheFooter>

		<div
			class="snack-bar"
			v-if="massage"
			:class="isError ? 'error' : 'success'">
			{{ massage }}
		</div>
	</div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue';
import TheFooter from '@/components/TheFooter.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { mapState } from 'vuex';

export default {
	name: 'App',
	components: {
		TheNavbar,
		TheFooter,
		LoadingComponent,
	},
	data() {
		return {
			load: false,
		};
	},
	computed: {
		...mapState({
			massage: (state) => state.snackbar.massage,
			isError: (state) => state.snackbar.isError,
		}),
	},
	methods: {
		auth() {
			if ('edunix-website-token' in localStorage) {
				this.load = false;
				console.log('in');

				const user = JSON.parse(
					localStorage.getItem('edunix-website-token'),
				);
				this.$store
					.dispatch('login/login', { user: user.user })
					.then(() => {
						this.load = true;
					});
			} else {
				console.log('out');
				this.load = true;
			}
		},
	},

	created() {
		if (this.$route.query.token) {
			this.$store
				.dispatch('login/loginWithCode', {
					code: this.$route.query.token,
				})
				.then(() => {
					this.load = true;
				});
		} else {
			this.auth();
		}
	},
};
</script>

<style lang="scss">
@import '@/scss/helpers.scss';
@import '@/scss/style.scss';

@font-face {
	font-family: mainFont;
	src: url('/public/Assets/Font/Cocon® Next Arabic-Light.otf');
}

@font-face {
	font-family: mainFont;
	src: url('/public/Assets/Font/Nizar Cocon Kurdish Bold.otf');
	font-weight: bold;
}

* {
	font-family: 'mainFont', sans-serif;
}
html,
body {
	max-width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;
}

.page {
	min-height: 70vh;
}

.text-justify * {
	text-align: justify !important;
}

.button-orange {
	cursor: pointer;
	border-radius: 2rem;
	padding: 0.5em 1.5em;
	text-align: center;
	background-color: $main-color;
	color: white;
	border: none;
	transition: 0.3s;
	width: max-content;
	&:hover:not(:disabled) {
		box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
			rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
			rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
			rgba(0, 0, 0, 0.07) 0px 32px 64px;
		transform: scale(1.03);
	}
	&:disabled {
		background-color: #ffaa92 !important;
	}
}

.button-store {
	background: $alt-color;
	border-radius: 10px;
	color: white;
	padding: 0.3em 0.8em;
	font-size: 0.9rem;
	transition: 0.3s;
	&:hover:not(:disabled) {
		box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
			rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
			rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px;
		opacity: 0.9;
	}
	&:disabled {
		opacity: 0.5;
	}
}

.button-loader {
	width: 1rem !important;
	height: 1rem !important;
}

.git-copy {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 20;
	background-color: #0003;
}
.git-copy-close {
	position: absolute;
	top: 20px;
	right: 20px;
	color: white;
	* {
		font-size: 1.5rem;
	}
}
.git-copy-container {
	max-width: 800px;
	background-color: white;
	padding: 30px;
	margin: 0 10px;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	h2 {
		text-align: start;
		[dir='rtl'] & {
			@media only screen and (min-width: 912px) {
				margin-right: 1rem;
			}
		}
	}
}
.snack-bar {
	position: fixed;
	right: 50%;
	transform: translateX(50%);
	max-width: 500px;
	padding: 10px;
	border-radius: 3px;
	z-index: 1000;
	&.error {
		border: 1px solid rgb(187, 0, 0);
		background-color: rgba(243, 87, 87, 0.91);
		color: white;
	}
	&.success {
		border: 1px solid rgb(8, 128, 0);
		background-color: rgba(130, 205, 121, 0.91);
		color: black;
	}
	unicode-bidi: embed;
	direction: ltr;
	animation: error-enter 0.4s forwards;
	@keyframes error-enter {
		from {
			bottom: 0;
			opacity: 0;
		}
		to {
			bottom: 20px;
			opacity: 1;
		}
	}
}
</style>

<template>
	<div class="bg-grey pb-5">
		<div
			v-if="!success"
			class="bg-grey w-100 d-flex align-items-center justify-content-center"
			style="height: 80vh">
			<div class="spinner-border"></div>
		</div>
		<div v-else></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Library',
	data() {
		return {
			success: false,
		};
	},
	computed: {
		...mapState({
			orders: (state) => state.orders.oreders,
		}),
	},
	methods: {
		fetch() {
			this.success - true;
			this.$store.dispatch('orders/fetchAllOrders').then(() => {
				this.success - true;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		features: [],
		extras: [],
	},
	actions: {
		fetchAllFeatures({ commit }) {
			return window.axios
				.get('/company-history', {
					params: {
						useOrder: true,
					},
				})
				.then((response) => {
					commit(types.FEATURES, response.data);
					return response.data;
				});
		},
		fetchFeaturesBySlug({ commit }, { slug }) {
			return window.axios.get(`/company-history/${slug}`).then((response) => {
				return response.data;
			});
		},
		fetchAllExtras({ commit }) {
			return window.axios
				.get('/company-values', {
					params: {
						useOrder: true,
					},
				})
				.then((response) => {
					commit(types.EXTRAS, response.data);
					return response.data;
				});
		},
		fetchExtrasBySlug({ commit }, { slug }) {
			return window.axios.get(`/company-values/${slug}`).then((response) => {
				return response.data;
			});
		},
	},
	mutations: {
		[types.FEATURES](state, features) {
			state.features = features;
		},
		[types.EXTRAS](state, extras) {
			state.extras = extras;
		},
	},
};

<template>
	<form
		class="get-copy-form row gx-3 gy-3 mx-0"
		@submit.prevent="send"
		ref="form">
		<div class="col-12 col-sm-6">
			<div class="input">
				<input
					required
					v-model="name"
					type="text"
					name="name"
					:placeholder="$t('contact-form.name')" />
			</div>
		</div>
		<div class="col-12 col-sm-6">
			<div class="input">
				<input
					required
					v-model="company"
					type="text"
					name="company-name"
					:placeholder="$t('contact-form.company')" />
			</div>
		</div>
		<div class="col-12 col-sm-6">
			<div class="input">
				<div class="dropdown">
					<div
						class="dropdown-label"
						@click="openDropdown = !openDropdown">
						<div
							class="d-flex"
							v-if="selectedCountry">
							<div class="image"><img :src="selectedCountry.image" /></div>
							<span>
								{{ selectedCountry.name }}
							</span>
						</div>
						<span v-else>الدولة</span>
						<i
							class="fa"
							:class="!openDropdown ? 'fa-angle-down' : 'fa-angle-up'"></i>
					</div>
					<div
						class="dropdown-list"
						:class="{ show: openDropdown }">
						<div
							v-for="(item, i) in countries"
							:key="i"
							class="dropdown-list-item"
							@click="selectCountry(item)">
							<div class="image">
								<img
									:src="item.image"
									alt="" />
							</div>
							<span>
								{{ item.name }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6">
			<div class="input">
				<input
					required
					v-model="phone"
					type="tel"
					name="phone"
					:placeholder="$t('contact-form.phone')" />
			</div>
		</div>
		<div class="col-12">
			<div class="input">
				<textarea
					required
					v-model="message"
					:placeholder="$t('contact-form.message')"
					rows="6"></textarea>
			</div>
		</div>
		<div class="col form-btn">
			<button
				type="submit"
				class="button-orange"
				:disabled="submitLoading">
				{{ $t('contact-form.submit') }}
				<div
					class="spinner-border button-loader"
					v-if="submitLoading"></div>
			</button>
		</div>
	</form>
</template>

<script>
export default {
	name: 'GetCopyForm',
	data() {
		return {
			name: null,
			phone: null,
			company: null,
			type: '',
			message: null,

			selectedCountry: null,
			openDropdown: false,

			submitLoading: false,

			countries: [
				{ name: 'القطر', code: '+974', image: require('@/assets/countries/qatar.png') },
				{ name: 'السعودية', code: '+966', image: require('@/assets/countries/saudi-arabia.png') },
				{ name: 'المغرب', code: '+212', image: require('@/assets/countries/morocco.png') },
				{
					name: 'الإمارات',
					code: '+971',
					image: require('@/assets/countries/united-arab-emirates.png'),
				},
				{ name: 'البحرين', code: '+973', image: require('@/assets/countries/bahrain.png') },
				{ name: 'عمان', code: '+968', image: require('@/assets/countries/oman.png') },
				{ name: 'الكويت', code: '+965', image: require('@/assets/countries/kuwait.png') },
				{ name: 'الأردن', code: '+962', image: require('@/assets/countries/jordan.png') },
				{ name: 'مصر', code: '+20', image: require('@/assets/countries/egypt.png') },
				{ name: 'سوريا', code: '+963', image: require('@/assets/countries/syria.png') },
				{ name: 'تونس', code: '+216', image: require('@/assets/countries/tunisia.png') },
				{ name: 'ليبيا', code: '+218', image: require('@/assets/countries/libya.png') },
				{ name: 'فلسطين', code: '+970', image: require('@/assets/countries/palestine.png') },
				{ name: 'الجزائر', code: '+213', image: require('@/assets/countries/algeria.png') },
				{ name: 'لبنان', code: '+961', image: require('@/assets/countries/lebanon.png') },
				{ name: 'السودان', code: '+249', image: require('@/assets/countries/sudan-circular.png') },
				{ name: 'العراق', code: '+964', image: require('@/assets/countries/iraq.png') },
				{ name: 'اليمن', code: '+967', image: require('@/assets/countries/yemen.png') },
			],
		};
	},
	methods: {
		send() {
			this.submitLoading = true;
			this.$store
				.dispatch('feedback/feedback', {
					name: this.name,
					email: this.company,
					phone: this.phone,
					type: this.type,
					body: this.message,
				})
				.finally(() => {
					this.submitLoading = false;
				});
		},
		selectCountry(item) {
			this.selectedCountry = item;
			this.openDropdown = false;
			this.phone = item.code;
		},
	},
};
</script>

<style lang="scss" scoped>
.get-copy-form {
	.input {
		border: 1px solid #ccc;
		border-radius: 20px;
		padding: 10px;
		width: 100%;
	}
	input,
	textarea,
	select {
		width: 100%;
		background-color: transparent;
		[dir='ltr'] & {
			text-align: left;
		}
		[dir='rtl'] & {
			text-align: right;
		}
	}
	.form-btn {
		max-width: 8rem;
		button {
			width: max-content;
		}
	}
}
.dropdown {
	position: relative;
	height: 1.5em;
	&-label {
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.image {
			height: 1.5rem;
			widows: 1.5rem;
			margin-left: 0.7rem;
		}
	}
	&-list {
		opacity: 0;
		transform: translateY(20px);
		transition: 0.5s;
		&.show {
			opacity: 1;
			transform: translateY(0);
		}
		background-color: white;
		border-radius: 1rem;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;
		padding: 10px;
		max-height: 200px;
		overflow: auto;
		&-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			margin: 3px 0;
			.image {
				height: 1.5rem;
				widows: 1.5rem;
				margin-left: 0.7rem;
			}
		}
	}
}
</style>

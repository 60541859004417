import * as types from '../mutation-types';

export default {
	namespaced: true,

	status: {
		about: [],
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/aboutus').then((res) => {
				commit(types.ABOUT_STORE, res.data);
				return res.data;
			});
		},
	},

	mutations: {
		[types.ABOUT_STORE](state, about) {
			state.about = about;
		},
	},

	getters: {
		getAbout: (state) => (key) =>
			state.about.find((e) => e.key === key)?.value,
	},
};

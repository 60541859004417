import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		faq: [],
	},
	actions: {
		fetchAllFaq({ commit }) {
			return window.axios
				.get('/custom-services', {
					params: {
						type: 3,
						typeName: 'frequently-question',
						useOrder: true,
					},
				})
				.then((response) => {
					commit(types.FAQ, response.data);
					return response.data;
				})
				.catch(() => {});
		},
	},
	mutations: {
		[types.FAQ](state, faq) {
			state.faq = faq;
		},
	},
};

import Home from '../views/Home.vue';
import features from '../views/Features.vue';
import EdunixStore from '../views/EdunixStore.vue';
import NewsView from '../views/News/NewsView.vue';
import AllNews from '../views/News/AllNews.vue';
import AllServices from '../views/AllServices.vue';
import AboutUs from '../views/AboutUs.vue';
import Login from '../views/Login.vue';
import Cart from '../views/Cart.vue';
import Library from '../views/Library.vue';
import Faq from '../views/Faq.vue';

export default [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/features/:slug',
		name: 'features',
		component: features,
	},
	{
		path: '/edunix',
		name: 'edunix',
		component: EdunixStore,
	},
	{
		path: '/news/:slug',
		name: 'newsView',
		component: NewsView,
	},
	{
		path: '/all-news',
		name: 'allNews',
		component: AllNews,
	},
	{
		path: '/services',
		name: 'allServices',
		component: AllServices,
	},
	{
		path: '/about-us',
		name: 'aboutUs',
		component: AboutUs,
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/cart',
		name: 'cart',
		component: Cart,
	},
	{
		path: '/library',
		name: 'library',
		component: Library,
	},
	{
		path: '/faq',
		name: 'faq',
		component: Faq,
	},
];

<template>
	<div class="features bg-grey pb-5 px-3">
		<div
			v-if="!success"
			class="bg-grey w-100 d-flex align-items-center justify-content-center"
			style="height: 80vh">
			<div class="spinner-border"></div>
		</div>
		<div
			class="d-block d-md-flex py-3"
			v-else>
			<list-categories class="mb-3"></list-categories>
			<div class="main-content">
				<div>
					<!-- breadcrumbs -->
					<div>
						<nav>
							<ul class="d-flex breadcrumbs p-0">
								<li class="mx-2 text-black">
									<!-- <router-link :to="{ name: 'home' }"> -->
									متجر إديونكس
									<!-- </router-link> -->
								</li>
								/
								<li class="mx-2">
									<!-- <router-link :to="{ name: '' }"> -->
									{{ category }}
									<!-- </router-link> -->
								</li>
								/
								<li class="mx-2 active">
									{{ product.name }}
								</li>
							</ul>
						</nav>
					</div>

					<!-- info -->
					<div
						class="d-flex justify-content-between align-items-end px-2 flex-wrap">
						<div>
							<!-- product name -->
							<h1 class="sec-text h2 text-capitalize">
								{{ product.name }}
							</h1>

							<!-- under name -->
							<div class="under-title">
								<!-- stars -->
								<span v-if="false">
									<i
										v-for="star in 4"
										:key="star"
										class="size-star fa fa-star"></i>
									<i class="fa fa-star size-star grey"></i>
									|
								</span>
								<!-- category -->
								<span class="text-capitalize">
									{{ category }}
								</span>
								<!-- code -->
								<span v-if="product.code">
									|
									{{ $t('FEATURES.Issuance') }}
									{{ product.code }}
								</span>
								<!-- featured -->
								<span v-if="product.isFeatured">
									|
									<span class="alt-text">
										{{ $t('FEATURES.special') }}
										<i class="fa fa-certificate"></i>
									</span>
								</span>
							</div>
						</div>

						<!-- btn and price -->
						<div class="d-flex align-items-end mt-2">
							<div class="price">
								{{
									new Intl.NumberFormat().format(
										Number(product.price),
									)
								}}
								{{ $t('FEATURES.price') }}
							</div>

							<button
								class="button-store"
								@click="addToCart"
								:disabled="
									productInCart(product.id) ||
									addingToCart ||
									isOrdered
								">
								{{
									isOrdered
										? $t('FEATURES.text-button-ordered')
										: productInCart(product.id)
										? $t('FEATURES.text-button-added')
										: $t('FEATURES.text-button')
								}}
								<span
									class="spinner-border button-loader"
									v-if="addingToCart"></span>
							</button>
						</div>
					</div>

					<!-- hr -->
					<div class="px-2">
						<hr class="w-100" />
					</div>

					<!-- main -->
					<div class="row gy-3 mx-0 mt-4">
						<div class="slider col-12 col-sm-6 col-md-12 col-lg-6">
							<hooper :settings="hooperSettings">
								<slide
									v-for="(img, i) in product.images"
									:key="i">
									<div
										class="image image--cover"
										@click="showLightbox(i)">
										<img
											:src="img.url"
											:alt="'features ' + i" />
									</div>
								</slide>

								<hooper-navigation
									slot="hooper-addons"></hooper-navigation>
								<hooper-pagination
									slot="hooper-addons"></hooper-pagination>
							</hooper>
						</div>

						<div
							class="text-features col-12 col-sm-6 col-md-12 col-lg-6">
							<div
								class="text-features"
								v-html="product.description"></div>
							<p>
								{{
									$t('FEATURES.Updated.Updated-in') +
									'  ' +
									moment(product.updatedAt).format(
										`YYYY-MM-DD`,
									)
								}}
							</p>
						</div>
					</div>
				</div>
			</div>
			<vue-easy-lightbox
				:visible="lightboxVisible"
				:imgs="product.images.map((e) => e.url)"
				:index="imageIndex"
				@hide="hideLightbox"></vue-easy-lightbox>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import ListCategories from '../components/ListCategories.vue';
import {
	Hooper,
	Slide,
	Pagination as HooperPagination,
	Navigation as HooperNavigation,
} from 'hooper';
import 'hooper/dist/hooper.css';

import VueEasyLightbox from 'vue-easy-lightbox';
// import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css';

export default {
	components: {
		ListCategories,
		Hooper,
		Slide,
		HooperNavigation,
		HooperPagination,
		VueEasyLightbox,
	},

	data() {
		return {
			product: {},
			success: false,

			addingToCart: false,

			hooperSettings: {
				rtl: true,
				wheelControl: false,
				autoPlay: true,
				playSpeed: 10000,
				itemsToShow: 1,
			},
			lightboxVisible: false,
			imageIndex: 0,
		};
	},

	computed: {
		isOrdered() {
			const ordersProduct = [
				...this.orders.map((o) => [...o.cart.products]),
			].flat();
			return ordersProduct.find((e) => e.product.id == this.product.id);
		},

		category() {
			let name = '';
			for (let i = 0; i < this.categoriesTree.length; i++) {
				const e = this.categoriesTree[i];
				if (name) break;
				if (e.id === this.product.categoriesIds[0]) name = e.name;
				if (e.children.length) {
					for (let j = 0; j < e.children.length; j++) {
						const ee = e.children[j];
						if (name) break;
						if (ee.id === this.product.categoriesIds[0])
							name = ee.name;
					}
				}
			}
			return name;
		},

		...mapState({
			products: (state) => state.products.products,
			categoriesTree: (state) => state.categories.categoriesTree,
			cart: (state) => state.cart.cart,
			orders: (state) => state.orders.orders,
		}),

		...mapGetters({
			productInCart: 'cart/productInCart',
		}),
	},

	methods: {
		moment,

		addToCart() {
			if (this.productInCart(this.product.id)) return;
			this.addingToCart = true;
			this.$store
				.dispatch('cart/addToCart', { productId: this.product.id })
				.then(() => {
					this.addingToCart = false;
				});
		},

		fetch() {
			this.success = false;
			Promise.all([
				this.$store.dispatch('orders/fetchAllOrders'),
				this.$store
					.dispatch('products/fetchProductBySlug', {
						slug: this.$route.params.slug,
					})
					.then((data) => {
						this.product = data;
					}),
				this.$store.dispatch('categories/fetchTreeCategories'),
			]).then(() => {
				this.success = true;
			});
		},

		showLightbox(i) {
			this.lightboxVisible = true;
			this.imageIndex = i;
		},
		hideLightbox() {
			this.lightboxVisible = false;
		},
	},

	created() {
		this.fetch();
	},

	metaInfo() {
		return {
			title:
				this.product.name + ' | ' + this.$i18n.t('title-page.features'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>
<style lang="scss" scoped>
@import '@/scss/style.scss';

.under-title * {
	font-size: 0.8rem;
}

.main-content {
	width: 1px;
	flex: 1;
	@media (max-width: 768px) {
		width: 100%;
	}
}

[dir='ltr'] .price {
	margin-right: 10px;
}
[dir='rtl'] .price {
	margin-left: 10px;
}
.breadcrumbs {
	list-style: none;
	> li {
		text-transform: capitalize;
		font-size: 0.8rem;
		&.active {
			color: $alt-color;
		}
		> a {
			color: inherit;
			text-decoration: none;
			&:hover {
				color: $alt-color;
			}
		}
	}
}
</style>

<style lang="scss">
.features {
	.hooper {
		height: 400px;
		@media (max-width: 576px) {
			height: 80vw;
		}
		.image {
			height: 100%;
			border-radius: 15px;
			overflow: hidden;
		}
	}
	.hooper-pagination {
		bottom: -30px;
		.hooper-indicator {
			height: 13px;
			width: 13px;
			border-radius: 50%;
			border: 1px solid #ccc;
			transition: 0.4s linear;
			background-color: transparent !important;
			&.is-active {
				border-width: 7px;
			}
		}
	}
}
</style>

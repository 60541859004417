<template>
	<div class="container py-5">
		<div
			v-if="!success"
			class="bg-grey w-100 d-flex align-items-center justify-content-center"
			style="height: 80vh">
			<div class="spinner-border"></div>
		</div>
		<div v-else>
			<!-- the cart is empty -->
			<div
				v-if="!products.length"
				class="text-center text-muted fs-4 text-uppercase py-5">
				{{ $t('cart.empty-cart') }}
			</div>

			<div v-else>
				<div class="row gy-3">
					<!-- the cart -->
					<div class="col-12 col-lg-8">
						<div class="cart-container cart-card">
							<h2 class="cart-title">{{ $t('cart.cart') }}</h2>
							<hr />
							<div class="cart-table">
								<!-- cart header -->
								<div class="cart-table__header">
									<div
										class="cart-table__header-item cart-table__header-item--start">
										{{ $t('cart.product-details') }}
									</div>
									<div class="cart-table__header-item">
										{{ $t('cart.categories') }}
									</div>
									<div class="cart-table__header-item">
										{{ $t('cart.price') }}
									</div>
									<div class="cart-table__header-item">
										{{ $t('cart.action') }}
									</div>
								</div>

								<!-- the cart content -->
								<div
									class="cart-table__content"
									v-for="item in cart.products"
									:key="item.id">
									<div class="cart-table__content-details">
										<router-link
											:to="{
												name: 'features',
												params: {
													slug: item.product.slug,
												},
											}">
											<div class="image">
												<img
													:src="
														item.product
															.primaryImage.url
													"
													alt="cart" />
											</div>
										</router-link>
										<router-link
											:to="{
												name: 'features',
												params: {
													slug: item.product.slug,
												},
											}">
											{{ item.product.name }}
										</router-link>
									</div>
									<div class="cart-table__content-category">
										<router-link
											:to="{
												name: 'edunix',
												query: {
													category: categories.find(
														(e) =>
															e.id ===
															item.product
																.categoriesIds[0],
													).slug,
												},
											}">
											{{
												categories.find(
													(e) =>
														e.id ===
														item.product
															.categoriesIds[0],
												).name
											}}
										</router-link>
									</div>
									<div class="cart-table__content-price">
										{{ item.product.price
										}}{{ $t('FEATURES.price') }}
									</div>
									<div class="cart-table__content-action">
										<div v-if="loadingDelete === item.id">
											<div class="spinner-border"></div>
										</div>
										<i
											v-else
											class="fa fa-trash-o"
											@click="deleteItem(item)"></i>
									</div>
								</div>
							</div>
						</div>

						<router-link
							:to="{ name: 'edunix' }"
							class="mt-5 d-none d-lg-inline-block">
							<i class="fa fa-long-arrow-left hide-arabic"></i>
							<i class="fa fa-long-arrow-right hide-english"></i>
							{{ $t('cart.continue') }}
						</router-link>
					</div>
					<!-- the cart summary -->
					<div class="col-12 col-lg-4">
						<div class="cart-card">
							<!-- title -->
							<h2 class="cart-title">
								{{ $t('cart.order-summary') }}
							</h2>
							<hr />
							<!-- summary -->
							<div
								class="summary-info d-flex align-items-center justify-content-between">
								<div>
									{{ $t('cart.items') }}
									{{ cart.products.length }}
								</div>
								<div>
									{{ cart.total }}{{ $t('FEATURES.price') }}
								</div>
							</div>
							<!-- checkout -->
							<div
								class="store-btn"
								@click="showGitCopy = true">
								{{ $t('cart.checkout') }}
							</div>
						</div>
					</div>
				</div>

				<!-- go to store -->
				<router-link
					:to="{ name: 'edunix' }"
					class="mt-5 d-lg-none d-inline-block">
					<i class="fa fa-long-arrow-left hide-arabic"></i>
					<i class="fa fa-long-arrow-right hide-english"></i>
					{{ $t('cart.continue') }}
				</router-link>
			</div>
		</div>

		<!-- git copy -->
		<div
			class="git-copy"
			:class="{ 'd-none': !showGitCopy }"
			@click.self="showGitCopy = false">
			<!-- close -->
			<div
				class="git-copy-close"
				@click="showGitCopy = false">
				<i class="fa fa-times"></i>
			</div>

			<!-- card -->
			<div class="git-copy-container">
				<!-- title -->
				<h2 class="h2 fw-bold mb-4">
					{{ $t('contact.get-copy') }}
				</h2>

				<!-- form -->
				<form
					class="row gx-3 gy-3 px-2 mx-0"
					@submit.prevent="submit">
					<!-- name -->
					<div class="col-12 col-sm-6">
						<input
							required
							v-model="name"
							type="text"
							name="name"
							:placeholder="$t('contact-form.name')" />
					</div>
					<!-- company -->
					<div class="col-12 col-sm-6">
						<input
							required
							v-model="company"
							type="text"
							name="company-name"
							:placeholder="$t('contact-form.company')" />
					</div>
					<!-- phone -->
					<div class="col-12 col-sm-6">
						<input
							required
							v-model="phone"
							type="tel"
							:placeholder="$t('contact-form.phone')" />
					</div>
					<!-- type -->
					<div class="col-12 col-sm-6">
						<select
							required
							v-model="type"
							name="type">
							<option
								value=""
								selected
								disabled
								hidden>
								select type
							</option>
							<option value="Quotation">Quotation</option>
							<option value="Inquiry">Inquiry</option>
							<option value="Suggestion">Suggestion</option>
							<option value="Complaint">Complaint</option>
						</select>
					</div>
					<!-- message -->
					<div class="col-12">
						<textarea
							required
							v-model="message"
							:placeholder="$t('contact-form.message')"
							rows="6"></textarea>
					</div>
					<!-- send button -->
					<div class="col home-form-btn">
						<button
							type="submit"
							class="button-orange"
							:disabled="submitLoading">
							{{ $t('contact-form.submit') }}
							<div
								class="spinner-border button-loader"
								v-if="submitLoading"></div>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Cart',
	data() {
		return {
			name: null,
			company: null,
			phone: null,
			message: null,
			email: null,
			type: null,

			success: false,
			submitLoading: false,
			loadingDelete: null,

			showGitCopy: false,
		};
	},

	computed: {
		products() {
			return this.cart.products;
		},
		...mapState({
			cart: (state) => state.cart.cart,
			user: (state) => state.login.user,
			categories: (state) => state.categories.categories,
		}),
	},

	methods: {
		submit() {
			this.submitLoading = true;
			Promise.all([
				this.$store.dispatch('feedback/feedback', {
					name: this.name,
					email: this.company,
					phone: this.phone,
					type: this.type,
					body: this.message,
				}),
				this.$store.dispatch('orders/placeOrder', {
					fullName: this.name,
					email: this.email,
					phone: this.phone,
					body: this.message,
					destination: this.company,
				}),
			])
				.then(() => {
					this.$store.commit(
						'snackbar/showSuccessSnackbar',
						this.$t('cart.messages.success'),
					);
					if (this.$fromEdunix) {
						this.$router.push({ name: 'edunix' });
					} else {
						this.$router.push({ name: 'home' });
					}
				})
				.catch(() => {
					this.$store.commit(
						'snackbar/showErrorSnackbar',
						this.$t('cart.messages.error'),
					);
				})
				.finally(() => {
					this.submitLoading = false;
				});
		},

		deleteItem(item) {
			this.loadingDelete = item.id;
			this.$store
				.dispatch('cart/deleteFromCart', {
					productId: item.product.id,
					itemId: item.id,
				})
				.then(() => {
					this.loadingDelete = null;
					this.success = false;
					this.$store.dispatch('cart/getCart').then(() => {
						this.success = true;
					});
				});
		},

		fetch() {
			this.success = false;
			Promise.all([
				this.$store.dispatch('categories/fetchAllCategories'),
			]).then(() => {
				this.name = this.user.fullname;
				this.company = this.user.fullname;
				this.phone = this.user.fullname;
				this.email = this.user.fullname;

				let productsName = '';
				this.cart.products.forEach((p, i) => {
					productsName += '\n' + (i + 1) + ' - ' + p.product.name;
				});
				this.message = 'أريد طلب هذه المنتجات:\n' + productsName;

				this.success = true;
			});
		},
	},
	mounted() {
		if (!this.user) {
			this.$router.push({ name: 'login' });
		} else {
			this.fetch();
		}
	},

	metaInfo() {
		return {
			title: this.$i18n.t('title-page.cart'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>

<style lang="scss" scoped>
@import '/src/scss/style.scss';

.cart-card {
	padding: 2rem;
	border-radius: 15px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.cart-title {
	font-size: 1.5rem;
	text-transform: capitalize;
}

.cart-table {
	&__header,
	&__content {
		text-align: center;
		display: grid;
		grid-template-columns: 3fr 2fr 2fr 1fr;
		align-items: center;
		@media (max-width: 768px) {
			grid-template-columns: 3fr 1fr;
			gap: 15px;
			grid-template-areas: 'details action' 'category price';
		}
	}
	&__header {
		margin-top: 2rem;
		@media (max-width: 768px) {
			display: none;
		}
		&-item {
			color: #999;
			text-transform: uppercase;
			font-size: 0.9rem;
			&--start {
				text-align: start !important;
			}
		}
	}
	hr {
		height: 1px;
		color: #ccc;
	}
	&__content {
		padding: 1.5rem 0;
		&:not(:last-child) {
			border-bottom: 1px solid #ccc;
		}
		&-details {
			display: flex;
			align-items: center;
			text-align: start !important;
			.image {
				height: 5rem;
				min-width: 5rem;
				max-width: 5rem;
				[dir='ltr'] & {
					margin-right: 0.7rem;
				}
				[dir='rtl'] & {
					margin-left: 0.7rem;
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 5px;
				}
			}
		}
		&-action {
			color: darkred;
			i {
				border-radius: 50%;
				padding: 10px;
				transition: 0.2s;
				cursor: pointer;
				&:hover {
					background-color: rgb(241, 198, 198);
				}
			}
		}

		@media (max-width: 768px) {
			&-details {
				grid-area: details;
			}
			&-category {
				grid-area: category;
				text-align: start !important;
			}
			&-price {
				grid-area: price;
			}
			&-action {
				grid-area: action;
			}
		}
	}
}

.summary-info {
	margin-bottom: 10rem;
	@media (max-width: 992px) {
		margin-bottom: 2rem;
	}
}

.store-btn {
	background: $alt-color;
	border-radius: 5px;
	color: white;
	padding: 0.6em 1.1em;
	text-align: center;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
			rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
			rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px;
		transform: scale(1.03);
	}
}

[dir='ltr'] .hide-english {
	display: none;
}
[dir='rtl'] .hide-arabic {
	display: none;
}
</style>

<template>
	<div>
		<div
			v-if="!success"
			class="bg-grey w-100 d-flex align-items-center justify-content-center"
			style="height: 80vh">
			<div class="spinner-border"></div>
		</div>
		<div
			v-else
			class="py-5">
			<!-- why edunix -->
			<section class="why-edunix container">
				<h2 class="why-edunix__title alt-text">
					{{ $t('about-us.why-edunix') }}
				</h2>
				<div
					class="why-edunix__text mt-4 text-justify"
					v-html="about.find((e) => e.key === 'about').value"></div>
			</section>

			<!-- partners -->
			<div
				class="container"
				v-if="albums.length">
				<section class="brands mt-4">
					<div
						class="brands__item"
						v-for="(img, i) in albums.find((e) => !e.video).images"
						:key="img">
						<div class="image">
							<img
								:src="img.url"
								:alt="'partners ' + i" />
						</div>
					</div>
				</section>
			</div>

			<!-- extras -->
			<section class="advantage container mt-5">
				<h2 class="alt-text fs-2">
					<span class="main-text fs-5 just-ar">
						{{ $t('about-us.advantage.sub-title') }}
						<br />
					</span>
					{{ $t('about-us.advantage.title') }}
					<span class="main-text fs-5 just-en">
						<br />{{ $t('about-us.advantage.sub-title') }}
					</span>
				</h2>
				<div class="row gy-3 mt-3">
					<div
						class="col-12 col-sm-6 col-lg-3"
						v-for="extra in extras"
						:key="extra.id">
						<advantage-card :data="extra"></advantage-card>
					</div>
				</div>
			</section>

			<!-- features -->
			<section class="features mt-5 py-4">
				<div class="container mb-4">
					<h2 class="alt-text">
						{{ $t('about-us.features') }}
					</h2>
				</div>
				<div class="container">
					<div class="row gy-5 justify-content-center">
						<div
							class="col-6 col-sm-4 col-md-3 col-lg-2"
							v-for="feature in features"
							:key="feature.id">
							<div class="features__image mb-2">
								<img
									:src="feature.primaryImage.url"
									:alt="feature.title + ' image'" />
							</div>
							<div class="features__title fw-bold">
								{{ feature.title }}
							</div>
							<div
								class="features__description"
								v-html="feature.content"></div>
						</div>
					</div>
				</div>
			</section>

			<!-- servises -->
			<section class="services container mt-5">
				<h2 class="alt-text mb-4">{{ $t('about-us.services') }}</h2>
				<div class="services-grid">
					<card-service
						v-for="(service, j) in services"
						:key="j"
						:service="service"></card-service>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import AdvantageCard from '@/components/AdvantageCard.vue';
import CardService from '@/components/CardService.vue';
import { mapState, mapGetters } from 'vuex';
export default {
	components: { AdvantageCard, CardService },
	name: 'AboutUs',

	data() {
		return {
			success: true,

			interval: null,
			centerItem: 0,
		};
	},
	methods: {
		fetch() {
			this.success = false;
			Promise.all([
				this.$store.dispatch('services/fetchAllServices'),
				this.$store.dispatch('values/fetchAllFeatures'),
				this.$store.dispatch('values/fetchAllExtras'),
				this.$store.dispatch('aboutus/fetchAll'),
				this.$store.dispatch('albums/fetchAllAlbums'),
			]).then(() => {
				this.success = true;
			});
		},
	},
	computed: {
		...mapState({
			services: (state) => state.services.services,
			features: (state) => state.values.features,
			extras: (state) => state.values.extras,
			about: (state) => state.aboutus.about,
			albums: (state) => state.albums.albums,
		}),
	},
	created() {
		this.fetch();
	},
	metaInfo() {
		return {
			title: this.$i18n.t('title-page.about'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/scss/style.scss';

.brands {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	&__item {
		min-width: 5rem;
		max-width: 5rem;
		margin: 10px;
		height: 5rem;
		.image {
			width: 100%;
			height: 100%;
			filter: grayscale(1);
			transition: 2s;
			&:hover {
				transition: 0.1s;
				filter: grayscale(0);
			}
		}
	}
}

h2 {
	font-weight: bold;
}

.features {
	&__image {
		height: 3rem;
		width: 3rem;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&__description {
		font-size: 0.85rem;
	}
}
.services-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	> * {
		margin: 0 1rem;
	}
}
</style>

<template>
	<footer class="footer">
		<!-- main footer -->
		<div class="top-footer container">
			<div
				class="d-block d-lg-flex align-items-center justify-content-between">
				<!-- brand -->
				<div class="d-block d-sm-flex mb-4 mb-lg-0">
					<div class="image">
						<img
							src="../../public/Assets/Logo/White logo.png"
							:alt="'edunix logo'" />
					</div>
					<div>
						<div>إديونكس</div>
						<div>لإدارة المؤسسات التعليمية</div>
					</div>
				</div>

				<div class="d-block d-sm-flex">
					<!-- links -->
					<ul
						class="footer-list d-block d-sm-flex align-items-center">
						<li>
							<router-link :to="{ name: 'home' }">
								{{ $t('link.home') }}
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'aboutUs' }">
								{{ $t('link.about-edunix') }}
							</router-link>
						</li>
						<li v-if="user">
							<router-link :to="{ name: 'edunix' }">
								{{ $t('link.edunix-store') }}
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'faq' }">
								{{ $t('link.faq') }}
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'allNews' }">
								{{ $t('link.news') }}
							</router-link>
						</li>
					</ul>

					<!-- numbers -->
					<div
						class="d-flex align-items-center me-0 me-sm-3 justify-content-center justify-content-sm-start">
						<div class="mx-2">
							<i class="fa fa-phone"></i>
						</div>
						<div
							v-if="contacts"
							class="numbers">
							<div
								class="mb-0 footer-number"
								dir="ltr">
								<a
									:href="`tel://${contacts.phone}`"
									target="_blank">
									{{ contacts.phone }}
								</a>
							</div>
							<div
								class="mb-0 footer-number"
								dir="ltr">
								<a
									:href="`tel://${contacts.mobile}`"
									target="_blank">
									{{ contacts.mobile }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- bottom footer -->
		<div class="bottom-footer container">
			<div class="d-flex justify-content-between align-items-center">
				<div v-if="contacts">
					<a
						class="px-4"
						:href="contacts.facebook"
						target="_blank">
						<i class="fa fa-facebook"></i>
					</a>
					<a
						:href="contacts.instagram"
						target="_blank">
						<i class="fa fa-instagram"></i>
					</a>
				</div>
				<div>
					<a
						class="font-bold"
						href="https://beetronix.com"
						title="beetronix website"
						target="_blank">
						BEETRONIX
					</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'TheFooter',

	data() {
		return {
			showGitCopy: false,
		};
	},

	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts,
			user: (state) => state.login.user,
		}),
	},

	mounted() {
		this.$store.dispatch('contacts/fetchAll');
	},
};
</script>

<style lang="scss" scoped>
@import '@/scss/style.scss';
* {
	color: white;
}
footer {
	background-color: $alt-color;
	padding: 2rem 0 10px;
	@media (max-width: 576px) {
		text-align: center;
	}
	.image {
		max-width: 7rem;
		@media (max-width: 576px) {
			margin: auto;
		}
	}
}
.top-footer {
	border-bottom: 1px solid white;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
}
.bottom-footer * {
	font-size: 0.8rem;
}
.footer-list {
	li {
		margin-left: 1rem;
		@media (max-width: 576px) {
			margin-bottom: 1rem;
			margin-left: 0;
		}
	}
}
.numbers * {
	font-size: 14px;
}
</style>

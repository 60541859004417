<template>
	<div class="card-services">
		<div class="image">
			<img
				:src="service.primaryImage.url"
				:alt="service.title + ' image'"
				class="img" />
		</div>
		<p>
			{{ service.title }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		service: Object,
	},
};
</script>
<style lang="scss">
.card-services {
	max-width: 190px;
	min-width: 190px;
	height: 280px;
	background-color: white;
	padding: 1rem;
	border-radius: 20px;
	box-shadow: 0.3px 2px 20px rgba(220, 215, 215, 0.475);
	transition: 0.3s;
	.image {
		height: 7rem;
		margin-bottom: 1rem;
	}
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	linkExactActiveClass: 'active',
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { y: 0, behavior: 'smooth' };
	},
});

export default router;

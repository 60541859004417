export const CATEGORIES = 'CATEGORIES';
export const CATEGORIES_TREE = 'CATEGORIES_TREE';

export const PRODUCTS = 'PRODUCTS';

export const PRODUCTS_RESALTE = 'PRODUCTS_RESALTE';

export const NEWS = 'NEWS';

export const SERVICES = 'SERVICES';

export const TESTIMONIAL = 'TESTIMONIAL';

export const CART = 'CART';

export const ORDERS = 'ORDERS';

export const FEATURES = 'FEATURES';

export const EXTRAS = 'EXTRAS';

export const ABOUT_STORE = 'ABOUT_STORE';

export const CONTACTS = 'CONTACTS';

export const ALBUMS = 'ALBUMS';

export const FAQ = 'FAQ';

import * as types from "../mutation-types"

export default {
    namespaced: true,
    state: {
        cart: {}
    },
    actions: {
        getCart({ commit }) {
            return window.axios.get('/cart').then((res) => {
                commit(types.CART, res.data)
            })
        },

        addToCart({ commit }, { productId }) {
            return window.axios.post(`/cart/${productId}`, { quantity: 1 }).then((res) => {
                commit(types.CART, res.data)
            })
        },

        deleteFromCart({ commit }, { productId, itemId }) {
            return window.axios.delete(`/cart/${productId}`, { params: { itemId } }).then((res) => {
                commit(types.CART, res.data)
            })
        },
    },
    mutations: {
        [types.CART](state, cart) {
            state.cart = cart
        }
    },
    getters: {
        productInCart: state => id => state.cart?.products?.find(e => e.product.id === id)
    }
}
<template>
    <div class="bg-grey w-100 h-100 position-absolute loading">
        <div class="spinner-border"></div>
    </div>
</template>

<script>
export default {
    props: ['load']
}
</script>
<style scoped>
.loading {
	top: 0%; 
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
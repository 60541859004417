import * as types from "../mutation-types"

export default {
    namespaced: true,
    state: {
        news: []
    },
    actions: {
        fetchAllNews({ commit }) {
            return window.axios.get('/news').then(response => {
                commit(types.NEWS, response.data)
                return response.data
            })
        },
        fetchNewsBySlug({ commit }, { slug }) {
            return window.axios.get(`/news/${slug}`).then(response => {
                return response.data
            })
        }
    },
    mutations: {
        [types.NEWS](state, news) {
            state.news = news
        }
    },
    getters: {
        getNewsBySlug: state => slug => state.news.find(e => e.slug === slug)
    }
}
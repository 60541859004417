<template>
	<div
		v-if="!success"
		class="bg-grey w-100 d-flex align-items-center justify-content-center"
		style="height: 100vh">
		<div class="spinner-border"></div>
	</div>
	<div
		class="container py-5"
		v-else>
		<h1 class="text-center alt-text mb-4">
			{{ $t('news-page.all-news') }}
		</h1>
		<div class="news-grid">
			<news-component
				:class="{ 'news-big': i === 0 }"
				v-for="(newsIndex, i) in news
					.map((e) => e)
					.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())"
				:key="newsIndex.id"
				:news="newsIndex"></news-component>
		</div>
	</div>
</template>

<script>
import NewsComponent from '@/components/NewsComponent.vue';
import { mapState } from 'vuex';
export default {
	name: 'AllNews',
	components: {
		NewsComponent,
	},
	data() {
		return {
			success: false,
		};
	},
	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	methods: {
		fetch() {
			this.success = false;
			this.$store.dispatch('news/fetchAllNews').then(() => {
				this.success = true;
			});
		},
	},
	mounted() {
		this.fetch();
	},
	metaInfo() {
		return {
			title: this.$i18n.t('title-page.news'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>

<style lang="scss" scoped>
.container {
	min-height: 90vh;
}
.news {
	&-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
		@media (max-width: 768px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (max-width: 450px) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&-big {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 3;
	}
}
// .news-grid {
// 	display: grid;
// 	gap: 10px;
// 	grid-template-columns: repeat(4, 1fr);
// 	@media (max-width: 992px) {
// 		grid-template-columns: repeat(3, 1fr);
// 	}
// 	@media (max-width: 576px) {
// 		grid-template-columns: repeat(2, 1fr);
// 	}
// }
</style>

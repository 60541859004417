<template>
	<div class="nav-container">
		<!-- top nav -->
		<div
			class="top-nav"
			ref="topNav">
			<div class="container py-2">
				<div
					class="d-flex flex-wrap justify-content-between align-items-center"
					v-if="contacts">
					<div>
						<a
							:href="contacts.facebook"
							target="_blank">
							<i class="fa fa-facebook"></i>
						</a>
						<a
							class="mx-4"
							:href="contacts.instagram"
							target="_blank">
							<i class="fa fa-instagram"></i>
						</a>
					</div>
					<div
						dir="ltr"
						class="d-flex">
						<div class="d-flex align-items-center mx-2">
							<i class="fa fa-phone mx-1"></i>
							<a
								:href="`tel://${contacts.phone}`"
								target="_blank">
								<p
									class="mb-0 footer-number"
									dir="ltr">
									{{ contacts.phone }}
								</p>
							</a>
						</div>
						<div class="d-flex align-items-center">
							<i
								class="fa fa-phone mx-1"
								aria-hidden="true"></i>
							<a
								:href="`tel://${contacts.mobile}`"
								target="_blank">
								<p
									class="mb-0 footer-number"
									dir="ltr">
									{{ contacts.mobile }}
								</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- main nav -->
		<div
			class="main-nav"
			ref="nav">
			<div class="container">
				<nav class="d-flex justify-content-between">
					<!-- brand -->
					<div class="brand">
						<router-link :to="{ name: 'home' }">
							<div class="image">
								<img
									class="edunix-logo"
									src="../../public/Assets/Logo/Logo.png"
									:alt="'edunix logo'" />
							</div>
						</router-link>
					</div>

					<!-- hamburger -->
					<div class="nav-toggle d-block d-md-none">
						<div class="image">
							<img
								@click="showList = !showList"
								class="btn-humbergr"
								src="../../public/Assets/Images/humbergerBtn.png"
								:alt="'nav toggle'" />
						</div>
					</div>

					<!-- link list -->
					<ul
						class="nav-list"
						:class="{ show: showList }">
						<!-- home -->
						<li>
							<router-link
								:to="{ name: 'home' }"
								class-active="active">
								{{ $t('link.home') }}
							</router-link>
						</li>

						<!-- about -->
						<li>
							<router-link
								:to="{ name: 'aboutUs' }"
								class-active="active">
								{{ $t('link.about-edunix') }}
							</router-link>
						</li>

						<!-- store -->
						<li v-if="user">
							<router-link
								:to="{ name: 'edunix' }"
								class-active="active">
								{{ $t('link.edunix-store') }}
							</router-link>
						</li>

						<!-- faq -->
						<li>
							<router-link
								:to="{ name: 'faq' }"
								class-active="active">
								{{ $t('link.faq') }}
							</router-link>
						</li>

						<!-- news -->
						<li>
							<router-link
								:to="{ name: 'allNews' }"
								class-active="active">
								{{ $t('link.news') }}
							</router-link>
						</li>

						<!-- git copy -->
						<li>
							<div
								class="button-orange"
								@click="showGitCopy = true">
								{{ $t('link.git') }}
							</div>
						</li>

						<!-- change lang -->
						<li v-if="false">
							<select
								v-model="$i18n.locale"
								@change="$emit('change-lang')"
								@click="$emit('change-value')"
								class="text-uppercase"
								style="border: none; background-color: transparent; cursor: pointer">
								<option
									v-for="(locale, i) in $i18n.availableLocales"
									:key="`locale${i}`"
									:value="locale"
									class="text-uppercase">
									{{ locale }}
								</option>
							</select>
						</li>

						<!-- login -->
						<li
							@click="changeLogin"
							v-if="0">
							<a role="button">
								<i
									class="fa"
									:class="user ? 'fa-sign-out' : 'fa-sign-in'"></i>
							</a>
						</li>

						<!-- cart -->
						<li v-if="user">
							<router-link :to="{ name: 'cart' }">
								<i class="fa fa-shopping-cart"></i>
							</router-link>
						</li>
					</ul>
				</nav>
			</div>
		</div>

		<div
			class="git-copy"
			:class="{ 'd-none': !showGitCopy }"
			@click.self="showGitCopy = false">
			<div
				class="git-copy-close"
				@click="showGitCopy = false">
				<i class="fa fa-times"></i>
			</div>
			<div class="git-copy-container">
				<h2 class="git-copy-title fw-bold mb-4">
					{{ $t('contact.get-copy') }}
				</h2>
				<get-copy-form></get-copy-form>
			</div>
		</div>

		<a
			v-if="contacts && contacts.whatsapp"
			:href="`https://wa.me/${contacts.whatsapp}`"
			class="whatsapp-btn">
			<i class="fa fa-whatsapp"></i>
		</a>
	</div>
</template>

<script>
import GetCopyForm from '@/components/GetCopyForm.vue';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			message: null,

			sendCopyLoading: false,

			showList: false,
			showGitCopy: false,
		};
	},
	props: {
		load: { type: Boolean },
	},
	components: {
		GetCopyForm,
	},
	model: {
		prop: 'load',
	},
	computed: {
		...mapState({
			user: (state) => state.login.user,
			contacts: (state) => state.contacts.contacts,
		}),
	},
	methods: {
		sendCopy() {
			this.sendCopyLoading = true;
			this.$store
				.dispatch('feedback/feedback', {
					name: this.name,
					email: this.email,
					phone: this.phone,
					type: 'request a feature',
					body: this.message,
				})
				.then(() => {
					this.$store.commit('snackbar/showSuccessSnackbar', this.$t('messages.copy-sent'));
					this.showGitCopy = false;
				})
				.catch(() => {
					this.$store.commit('snackbar/showErrorSnackbar', this.$t('messages.error'));
				})
				.finally(() => {
					this.sendCopyLoading = false;
				});
		},
		changeLogin() {
			if (this.user) {
				this.$store.dispatch('login/logout');
			} else {
				this.$route.name !== 'login' && this.$router.push({ name: 'login' });
			}
		},
	},
	created() {
		this.$eventBus.$on('show-get-copy', () => {
			this.showGitCopy = true;
		});
		window.addEventListener('scroll', () => {
			this.$refs.nav.style.top =
				(this.$refs.topNav.clientHeight - document.documentElement.scrollTop > 0
					? this.$refs.topNav.clientHeight - document.documentElement.scrollTop
					: 0) + 'px';
		});
	},
};
</script>
<style lang="scss" scoped>
@import '@/scss/style.scss';
* {
	font-size: 0.8rem;
}
.git-copy-title {
	font-size: calc(1.325rem + 0.9vw);
}
.nav-container {
	position: relative;
	z-index: 3;
	padding-bottom: 75px;
}
.top-nav {
	background-color: #bdd2e8;
	a:hover {
		color: $main-color;
	}
}

.brand {
	height: 4rem;
	@media (max-width: 992px) {
		height: 3rem;
	}
	.image {
		height: 100%;
	}
}

.nav-toggle {
	width: 2rem;
	.image {
		height: 100%;
	}
}

.main-nav {
	padding: 10px 0;
	position: fixed;
	right: 0;
	left: 0;
	background-image: linear-gradient(to bottom, #fffd 50%, #0000);
}

@media (min-width: 768px) {
	.nav-list {
		display: flex;
		align-items: center;
		li {
			margin-right: 1.5rem;
			@media (max-width: 992px) {
				margin-right: 0.6rem;
			}
			&:hover {
				color: $main-color;
			}
		}
	}
}

@media (max-width: 768px) {
	.nav-list {
		position: absolute;
		top: 100px;
		right: 20px;
		left: 20px;
		background-color: white;
		padding: 2rem;
		border-radius: 15px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
		text-align: center;
		z-index: 1;
		li:not(:last-child) {
			padding: 3px;
			width: max-content;
			margin: auto;
			margin-bottom: 1rem;
		}

		transform: translateX(150%);
		transition: 200ms ease-in-out;
		&.show {
			transform: translateX(0);
		}
	}
}
.whatsapp-btn {
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #075e54;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	color: white;
	opacity: 0.7;
	&:hover {
		opacity: 1;
	}
	* {
		font-size: 1.4rem;
	}
}
</style>
<style lang="scss">
@import '@/scss/style.scss';
li .active {
	color: $main-color !important;
}
</style>

import * as types from '../mutation-types';
export default {
	namespaced: true,
	state: {
		products: [],
		resalte: {},
	},
	actions: {
		fetchAllProducts({ commit, state }) {
			return state.products.length
				? state.products
				: window.axios.get('/products').then((response) => {
						commit(types.PRODUCTS, response.data);
						return response.data;
				});
		},
		fetchProductBySlug({ commit }, { slug }) {
			return window.axios.get(`/products/${slug}`).then((response) => {
				return response.data;
			});
		},
		search({ commit }, { text }) {
			return window.axios
				.get('/search', {
					params: {
						text,
					},
				})
				.then((response) => {
					return response.data.products;
				});
		},
	},
	mutations: {
		[types.PRODUCTS](state, products) {
			state.products = products;
		},
	},
};

<template>
	<div
		v-if="!success"
		class="bg-grey w-100 d-flex align-items-center justify-content-center"
		style="height: 100vh">
		<div class="spinner-border"></div>
	</div>
	<div v-else>
		<header class="header">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-12 col-md-7 header-text">
						<h1 class="header-text__title mb-5">
							<span>أنجز أكثر مع </span>
							<span class="main-text">إديونكس </span>
						</h1>
						<div
							class="d-flex align-items-center justify-content-center justify-content-md-start mb-3">
							<div
								class="button-orange"
								@click="$eventBus.$emit('show-get-copy')">
								اطلب نسختك الآن
							</div>
							<div
								class="d-flex align-items-center"
								v-if="false">
								<div class="video-button main-color center-item mx-2">
									<i class="fa fa-play"></i>
								</div>
								<div>شاهد الفيديو</div>
							</div>
						</div>
						<div
							class="d-flex align-items-center justify-content-center justify-content-md-start mb-3">
							<div class="ms-3">يعمل على</div>
							<div class="mx-2">
								<i class="h2 fa fa-windows"></i>
							</div>
							<div class="mx-2">
								<i class="h2 fa fa-android"></i>
							</div>
							<div class="mx-2">
								<i class="h2 fa fa-apple"></i>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-5 header-image d-none d-md-block">
						<div class="image">
							<img
								src="/Assets/Images/header-image.png"
								alt="smiley man" />
						</div>
						<div class="header-image-overflow">
							<div class="image">
								<img
									src="/Assets/Shapes/Polygon 1.png"
									alt="background shape" />
							</div>
							<div class="fill-width fill-height image">
								<img
									src="/Assets/Shapes/Polygon 2.png"
									alt="background shape" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>

		<!-- section brands -->
		<section class="pb-0">
			<h2 class="text-center main-text mb-2 h3 my-3">شركاء النجاح في نظام إديونكس</h2>
			<div class="container">
				<div
					class="brands"
					v-if="albums.length">
					<div
						class="brands__item"
						v-for="(img, i) in albums.find((e) => !e.video).images"
						:key="i">
						<div class="image">
							<img
								:src="img.url"
								:alt="'partners ' + i"
								class="" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- eduinx -->
		<section
			class="eduinx"
			v-if="about('about')">
			<div class="container">
				<div class="row gx-5 gy-3">
					<div class="col-12 col-md-6">
						<h2 class="main-text mb-2 h1">ما هو نظام إديونكس؟</h2>
						<h6 class="mb-2 h5"></h6>
						<div
							v-html="about('about')"
							class="text-justify"></div>
					</div>

					<div class="col-12 col-md-6">
						<div class="first-image">
							<img
								src="/Assets/Images/home-1.png"
								alt="edunix application" />
							<video
								v-if="albums.find((e) => e.video)"
								class="main-video"
								preload="metadata"
								poster="/Assets/Images/poster.jpg"
								controls
								autoplay
								muted
								playsinline>
								<source
									:src="albums.find((e) => e.video).video"
									type="video/mp4" />
							</video>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- eduinx mobile -->
		<section
			class="eduinx-mobile"
			v-if="about('mission')">
			<div class="container">
				<div class="row flex-row-reverse">
					<div class="col-12 col-md-5 py-5">
						<h2 class="h3 main-text mb-2">تطبيق الطلاب والأهالي</h2>
						<div
							v-html="about('mission')"
							class="text-justify"></div>
					</div>
					<div class="col-12 col-md-7">
						<div class="image h-100">
							<img
								src="/Assets/Images/home-2.png"
								alt="edunix mobile application" />
						</div>
					</div>
				</div>
				<div class="shape">
					<div class="image">
						<img
							src="/Assets/Shapes/Polygon 5.png"
							alt="shape" />
					</div>
				</div>
			</div>
		</section>

		<!-- eduinx -->
		<section
			class="eduinx pt-2"
			v-if="about('vision')">
			<div class="container">
				<div class="row gx-5 gy-3">
					<div class="col-12 col-md-6">
						<h2 class="main-text mb-2 h1">نظام الإدارة</h2>
						<div
							v-html="about('vision')"
							class="text-justify"></div>
					</div>

					<div class="col-12 col-md-6">
						<div class="image h-100 position-relative">
							<img
								src="/Assets/Images/home-3.png"
								alt="edunix application" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- statics -->
		<section class="statics py-0">
			<div class="container">
				<div class="row gy-3">
					<div class="col-6 col-md-3">
						<div class="statics__item">
							<div class="image">
								<img
									src="/Assets/Icons/student.png"
									alt="student" />
							</div>
							<div class="h1 light-text fw-black mb-0">50K</div>
							<div class="h5">طالب</div>
						</div>
					</div>
					<div class="col-6 col-md-3">
						<div class="statics__item">
							<div class="image">
								<img
									src="/Assets/Icons/customer-service.png"
									alt="Technical Support" />
							</div>
							<div class="h1 light-text fw-black mb-0">800h+</div>
							<div class="h5">دعم فني</div>
						</div>
					</div>
					<div class="col-6 col-md-3">
						<div class="statics__item">
							<div class="image">
								<img
									src="/Assets/Icons/group.png"
									alt="trainee" />
							</div>
							<div class="h1 light-text fw-black mb-0">128</div>
							<div class="h5">متدرب</div>
						</div>
					</div>
					<div class="col-6 col-md-3">
						<div class="statics__item">
							<div class="image">
								<img
									src="/Assets/Icons/certificate.png"
									alt="certificate" />
							</div>
							<div class="h1 light-text fw-black mb-0">+8K</div>
							<div class="h5">شهادة</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- News -->
		<section class="news">
			<div class="container">
				<h2 class="sec-text text-center h1 mb-5">فعاليات وأخبار</h2>
				<div class="news-grid gy-3">
					<news-component
						class="news-big"
						:news="newsArr[0]">
					</news-component>

					<news-component
						class="news-small"
						:class="{ 'd-none d-md-flex': i >= 2 }"
						v-for="(newsIndex, i) in newsArr.slice(1, 5)"
						:key="i"
						:news="newsIndex">
					</news-component>
				</div>
			</div>
		</section>

		<!-- multi platform -->
		<section class="multi-platform">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-5">
						<h2 class="main-text h1 mb-4">نظام متعدد المنصات</h2>
						<h3 class="h5 mb-3">
							كن السباق بإدخال نظام إديونكس لعملك لتنتقل لجيل احدث من أنظمة إدارة التعليم
						</h3>
						<p class="mb-1">اديونكس يدعم العمل على عدة أنطمة</p>
						<ul class="multi-platform__list">
							<li class="mb-0">windows</li>
							<li class="mb-0">Android</li>
							<li class="mb-0">iOS</li>
						</ul>
					</div>
					<div class="col-12 col-md-7">
						<div class="image h-100">
							<img
								src="/Assets/Images/home-4.png"
								alt="edunix application fro multi platform" />
						</div>
					</div>
				</div>
				<div class="shape">
					<div class="image">
						<img
							src="/Assets/Shapes/Polygon 5.png"
							alt="background shape" />
					</div>
				</div>
			</div>
		</section>

		<!-- features -->
		<section
			class="features pb-4 pt-0"
			v-if="features.length">
			<div class="container">
				<h2 class="text-center h1 main-text mb-5">مميزات نظام إديونكس</h2>
				<div class="row gy-4 mb-4">
					<div
						class="col-6 col-sm-4 col-md-3 col-lg-2"
						:class="{ 'd-none d-md-block': i > 7 }"
						v-for="(feature, i) in features"
						:key="i">
						<div class="features__card">
							<div class="features__card-image mb-2">
								<div class="image">
									<img
										:src="feature.primaryImage.url"
										:alt="feature.title + ' image'" />
								</div>
							</div>
							<div class="features__card-name mb-1">
								{{ feature.title }}
							</div>
							<!-- <div
								class="features__card-description"
								v-html="feature.content"></div> -->
						</div>
					</div>
				</div>
				<!-- <div class="button-orange mx-auto">كل المميزات</div> -->
			</div>
		</section>

		<!-- testimonial -->
		<section
			class="testimonial"
			v-if="typeof testimonial === 'object' && testimonial.length">
			<div class="container-lg px-3 px-sm-0 px-md-3">
				<h2 class="h1 text-center sec-text mb-4 text-center">آراء العملاء</h2>
				<div class="slider">
					<hooper :settings="hooperSettings">
						<slide
							v-for="item in testimonial"
							:key="item.id">
							<div class="testimonial__item text-center">
								<div class="testimonial__item-card">
									<div
										class="testimonial__item-card-content"
										v-html="item.content"></div>
								</div>
								<div class="testimonial__item-profile">
									<div class="testimonial__item-profile-image mb-2">
										<div class="image">
											<img
												:src="item.primaryImage.url"
												:alt="item.title + ' image'" />
										</div>
									</div>
									<div class="mb-1">{{ item.title }}</div>
									<div class="mb-1">{{ item.summary }}</div>
								</div>
							</div>
						</slide>
					</hooper>
				</div>
			</div>
		</section>

		<!-- contact -->
		<section class="contact">
			<div class="container">
				<div class="row align-items-end">
					<div class="col-12 col-lg-8 pb-5">
						<h2 class="h1 main-text mb-1">اطلب نسختك الآن</h2>
						<h3 class="mb-4 h6">
							املأ معلوماتك في النموذج أدناه للتواصل مع فريق إديونكس والحصول على خدمات مميزة
							وفريدة في الوسط التعليمي
						</h3>
						<get-copy-form></get-copy-form>
					</div>
					<div class="col-12 col-lg-4 contact__image d-none d-lg-block">
						<div class="image">
							<img
								src="../../public/Assets/Images/Contact us.png"
								alt="smiley man point to the form"
								class="contact-container__image" />
						</div>
					</div>
				</div>
				<div class="shape">
					<div class="image">
						<img
							src="/Assets/Shapes/Polygon 5.png"
							alt="background shape" />
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import GetCopyForm from '@/components/GetCopyForm.vue';
import NewsComponent from '@/components/NewsComponent.vue';
import { mapState, mapGetters } from 'vuex';
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
	name: 'HomePage',
	components: {
		NewsComponent,
		GetCopyForm,
		Hooper,
		Slide,
	},

	data() {
		return {
			success: false,

			hooperSettings: {
				centerMode: true,
				rtl: true,
				wheelControl: false,
				autoPlay: true,
				playSpeed: 10000,
				itemsToShow: 1,
				breakpoints: {
					768: {
						itemsToShow: 3,
					},
					576: {
						itemsToShow: 2,
					},
				},
			},
		};
	},

	methods: {
		fetch() {
			this.success = false;
			Promise.all([
				this.$store.dispatch('news/fetchAllNews'),
				this.$store.dispatch('services/fetchAllServices'),
				this.$store.dispatch('testimonial/fetchAllTestimonial'),
				this.$store.dispatch('aboutus/fetchAll'),
				this.$store.dispatch('values/fetchAllFeatures'),
				this.$store.dispatch('albums/fetchAllAlbums'),
			]).then(() => {
				this.success = true;
			});
		},
	},

	computed: {
		newsArr() {
			return [...this.news.filter((e) => e.isFeatured), ...this.news.filter((e) => !e.isFeatured)];
		},
		...mapState({
			news: (state) => state.news.news,
			services: (state) => state.services.services,
			testimonial: (state) => state.testimonial.testimonial,
			features: (state) => state.values.features,
			albums: (state) => state.albums.albums,
		}),
		...mapGetters({
			about: 'aboutus/getAbout',
		}),
	},

	created() {
		this.fetch();
	},

	metaInfo() {
		return {
			title: this.$i18n.t('title-page.home'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>
<style lang="scss" scoped>
@import '/src/scss/style.scss';

.header {
	overflow: hidden;
	background-image: url('/public/Assets/Images/Header background.jpg');
	background-size: cover;
	background-position: center;
	padding-top: 6rem;
	margin-top: -110px;
	@media (max-width: 768px) {
		padding: 8rem 0 2rem;
	}
	&-text {
		z-index: 2;
		&__title {
			font-size: 4rem;
			span {
				display: block;
			}
		}
		@media (max-width: 768px) {
			text-align: center;
		}
	}
	&-image {
		> .image {
			width: 110%;
			float: left;
		}
		position: relative;
		z-index: 1;
		&-overflow {
			position: absolute;
			right: -120px;
			top: 20px;
			width: 150%;
			z-index: -1;
			.image:nth-child(1) {
				transform: rotate(-20deg);
			}
			.image:nth-child(2) {
				transform: rotate(-20deg) translate(-60px, 40px) scale(0.95);
			}
		}
	}
}

.video-button {
	width: 2.5rem;
	height: 2.5rem;
	background-color: white;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	i {
		color: $main-color;
	}
}

section {
	padding: 2rem 0;
}

.shape {
	position: absolute;
	z-index: -1;
	.image {
		width: 100%;
		height: 100%;
	}
}

.brands {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	&__item {
		min-width: 5rem;
		max-width: 5rem;
		margin: 10px;
		height: 5rem;
		.image {
			width: 100%;
			height: 100%;
			filter: grayscale(1);
			transition: 2s;
			&:hover {
				transition: 0.1s;
				filter: grayscale(0);
			}
		}
	}
}

.eduinx-mobile {
	.container {
		position: relative;
		.shape {
			right: -90px;
			top: -100px;
			width: 50vw;
			max-width: 800px;
			@media (max-width: 992px) {
				right: -190px;
				top: -20px;
			}
			@media (max-width: 768px) {
				right: -30px;
				top: 25%;
				width: 100%;
			}
		}
	}
}

.statics {
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: auto;
		.image {
			margin: auto;
			margin-bottom: 0.5rem;
			width: 2.5rem;
			height: 2.5rem;
			transition: 0s;
		}
		&:hover .image {
			transition: 1s;
			transform: rotateY(360deg);
		}
	}
}

.news {
	&-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
		@media (max-width: 768px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (max-width: 450px) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&-big {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 3;
	}
}

.multi-platform {
	&__list {
		list-style: disc;
		list-style-position: inside;
		li {
			margin-bottom: 0.3rem;
		}
	}
	.container {
		position: relative;

		.shape {
			left: -190px;
			top: -130px;
			width: 55vw;
			max-width: 800px;
			@media (max-width: 768px) {
				left: -50px;
				top: 30%;
				width: 100%;
			}
		}
	}
}

.features {
	background-image: url('/public/Assets/Images/features-bg.jpg');
	background-size: 100% calc(100% - 200px);
	background-position: center bottom;
	perspective: 1000px;
	&__card {
		padding: 1rem;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		background-color: white;
		border-radius: 15px;
		text-align: center;
		transition: transform 0.5s ease;
		transform-origin: center;
		&-image {
			margin: auto;
			width: 4rem;
			height: 4rem;
			.image {
				width: 100%;
				height: 100%;
			}
		}
		&-name {
			height: 3em;
		}
		&-description {
			font-size: 0.8rem;
			height: 3em;
			overflow: hidden;
		}
	}
}

.testimonial {
	&__item {
		padding: 15px;
		padding-bottom: 8rem;
		position: relative;
		&-card {
			background-image: url('/public/Assets/Icons/double-quotes.png');
			background-size: 5rem 5rem;
			background-position: center 20%;
			border-radius: 15px;
			background-color: white;
			box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
			padding: 4rem 2rem 3rem;
			&-content {
				max-height: 6em;
				overflow: hidden;
			}
		}
		&-profile {
			position: absolute;
			right: 0;
			left: 0%;
			top: 55%;
			* {
				font-size: 0.7rem;
			}
			&-image {
				margin: auto;
				width: 3.5rem;
				height: 3.5rem;
				border: 1px solid white;
				border-radius: 50%;
				overflow: hidden;
				.image {
					widows: 100%;
					height: 100%;
				}
				transition: 0.3s;
			}
		}
		&:hover &-profile-image {
			transform: scale(1.1);
		}
	}
}

.contact {
	.container {
		position: relative;
		.shape {
			left: -200px;
			bottom: -120px;
			width: 50vw;
			max-width: 700px;
			@media (max-width: 768px) {
				width: 100%;
				top: -100px;
				left: -100px;
			}
		}
	}
	&__image {
		direction: ltr;
		.image {
			width: 135%;
		}
	}
}

.first-image {
	aspect-ratio: 1000 / 644;
	position: relative;
	img {
		width: 100%;
		height: 100%;
	}
	video {
		position: absolute;
		top: 2%;
		left: 13%;
		width: 75%;
	}
}
</style>

<style lang="scss">
@import '/src/scss/style.scss';
.testimonial {
	.hooper {
		padding: 0 !important;
		height: 520px;
		@media (max-width: 576px) {
			height: 320px;
		}
		.hooper-list {
			padding-top: 100px;
			@media (max-width: 576px) {
				padding-top: 50px;
			}
		}
		.hooper-slide {
			transition: 0.5s;
		}
	}
	.hooper-slide.is-current {
		transform: scale(1.2) translateY(-50px);
		@media (max-width: 576px) {
			transform: translateY(-50px);
		}
		z-index: 2;
		.testimonial__item {
			&-card {
				background-color: $light-color;
				color: white;
			}
		}
	}
}
.features__card-description * {
	font-size: 0.8rem;
	font-weight: normal;
}
</style>

<template>
	<div
		class="container"
		:class="{ 'my-5': !$fromEdunix }">
		<div class="card-container">
			<!-- login -->
			<div
				key="log in"
				class="login-container text-center"
				v-if="isLogin">
				<h1 class="mb-4 text-uppercase">{{ $t('login.login') }}</h1>
				<form @submit.prevent="login">
					<div class="mb-3">
						<label for="login-name">{{ $t('login.name') }}</label>
						<input
							v-model="loginUserName"
							required
							type="text"
							id="login-name" />
					</div>
					<div class="mb-4">
						<label
							for="login-password"
							@click="showPassword"
							data-password="true">
							{{ $t('login.password') }}
						</label>
						<input
							v-model="loginPassword"
							required
							type="password" />
					</div>
					<button
						type="submit"
						class="button-orange mb-3"
						:disabled="loading">
						{{ $t('login.login') }}
						<div
							class="spinner-border button-loader"
							v-if="loading"></div>
					</button>
					<p
						@click="
							resetAll();
							isSignup = true;
						"
						class="change-form-btn clickable">
						{{ $t('login.make-account') }}
					</p>
					<p
						class="footer-text clickable"
						@click="
							resetAll();
							isForgetPassword = true;
						">
						{{ $t('login.forget-password') }}
					</p>
				</form>
			</div>

			<!-- sign up -->
			<div
				key="sign up"
				class="signup-container text-center"
				v-if="isSignup">
				<h1 class="mb-4 text-uppercase">
					{{ $t('login.signup') }}
				</h1>
				<form @submit.prevent="register">
					<div class="mb-3">
						<label for="signup-name">{{
							$t('login.full-name')
						}}</label>
						<input
							v-model="signupName"
							required
							type="text"
							id="signup-name" />
					</div>
					<div class="mb-3">
						<label for="signup-name">{{
							$t('login.user-name')
						}}</label>
						<input
							v-model="signupUserName"
							required
							type="text"
							id="signup-name" />
					</div>
					<div class="mb-3">
						<label for="signup-name">{{ $t('login.email') }}</label>
						<input
							v-model="signupEmail"
							required
							type="email"
							id="signup-name" />
					</div>
					<div class="mb-3">
						<label
							for="signup-password"
							@click="showPassword"
							data-password="true">
							{{ $t('login.password') }}
						</label>
						<input
							v-model="signupPassword"
							required
							minlength="4"
							type="password"
							id="signup-password" />
					</div>
					<div class="mb-4">
						<label
							for="signup-confirm-password"
							@click="showPassword"
							data-password="true">
							{{ $t('login.confirm-password') }}
						</label>
						<input
							v-model="signupConfirmPassword"
							required
							type="password"
							id="signup-confirm-password" />
					</div>
					<button
						type="submit"
						class="button-orange mb-3"
						:disabled="loading">
						{{ $t('login.signup') }}

						<div
							class="spinner-border button-loader"
							v-if="loading"></div>
					</button>
					<p
						@click="
							resetAll();
							isLogin = true;
						"
						class="change-form-btn clickable">
						{{ $t('login.have-account') }}
					</p>
				</form>
			</div>

			<!-- forget password enter email -->
			<div
				key="forget password email"
				v-if="isForgetPassword"
				class="text-center">
				<h1 class="mb-4 text-uppercase fs-3">
					{{ $t('login.forget-password-form.title') }}
				</h1>
				<form @submit.prevent="sendForgetPassword">
					<div class="mb-4">
						<label for="login-name">{{
							$t('login.forget-password-form.enter-email')
						}}</label>
						<input
							v-model="forgetPasswordEmail"
							required
							type="email"
							id="login-name" />
					</div>
					<button
						type="submit"
						class="button-orange mb-3"
						:disabled="loading">
						{{ $t('login.forget-password-form.confirm') }}
						<div
							class="spinner-border button-loader"
							v-if="loading"></div>
					</button>
					<p
						class="footer-text clickable"
						@click="
							resetAll();
							isLogin = true;
						">
						{{ $t('login.forget-password-form.try-again') }}
					</p>
				</form>
			</div>

			<!-- forget password enter code -->
			<div
				key="forget password code"
				v-if="isForgetPasswordCode"
				class="text-center">
				<h1 class="mb-4 text-uppercase fs-3">
					{{ $t('login.enter-code.title') }}
				</h1>
				<form @submit.prevent="sendForgetPasswordCode">
					<div class="mb-4">
						<label for="login-name">{{
							$t('login.enter-code.enter-code')
						}}</label>
						<input
							v-model="forgetPasswordCode"
							required
							type="text"
							id="login-name" />
						<p class="footer-text text-muted">
							{{ $t('login.enter-code.info') }}
						</p>
					</div>
					<button
						type="submit"
						class="button-orange mb-3"
						:disabled="loading">
						{{ $t('login.enter-code.confirm') }}
						<div
							class="spinner-border button-loader"
							v-if="loading"></div>
					</button>
					<p
						class="footer-text"
						@click="
							resetAll();
							isLogin = true;
						">
						{{ $t('login.enter-code.back') }}
					</p>
				</form>
			</div>

			<!-- forget password enter edit -->
			<div
				key="forget password reset"
				v-if="isForgetPasswordEdit"
				class="text-center">
				<h1 class="mb-4 text-uppercase fs-3">
					{{ $t('login.edit.title') }}
				</h1>
				<form @submit.prevent="sendForgetPasswordEdit">
					<div class="mb-3">
						<label for="login-name">{{
							$t('login.edit.enter-password')
						}}</label>
						<input
							v-model="forgetPasswordEdit"
							required
							type="text"
							id="login-name" />
					</div>
					<div class="mb-4">
						<label for="login-name">{{
							$t('login.edit.enter-confirm')
						}}</label>
						<input
							v-model="forgetPasswordEditConfirm"
							required
							type="text"
							id="login-name" />
					</div>
					<button
						type="submit"
						class="button-orange mb-3"
						:disabled="loading">
						{{ $t('login.edit.confirm') }}
						<div
							class="spinner-border button-loader"
							v-if="loading"></div>
					</button>
					<p
						class="footer-text"
						@click="
							resetAll();
							isLogin = true;
						">
						{{ $t('login.edit.back') }}
					</p>
				</form>
			</div>

			<div v-if="isLogin || isSignup">
				<hr />
				<button
					class="google-btn"
					@click="registerWithGoogle"
					:disabled="loadingGoogle">
					<div
						class="spinner-border button-loader"
						v-if="loadingGoogle"></div>
					<template v-else>
						{{ $t('login.google') }} <i class="fa fa-google"></i>
					</template>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Login',
	data() {
		return {
			loading: false,
			isLogin: false,
			isSignup: false,
			isForgetPassword: false,
			isForgetPasswordCode: false,
			isForgetPasswordEdit: false,
			loadingGoogle: false,

			signupName: null,
			signupUserName: null,
			signupEmail: null,
			signupPassword: null,
			signupConfirmPassword: null,

			loginUserName: null,
			loginPassword: null,

			forgetPasswordEmail: null,
			forgetPasswordCode: null,
			forgetPasswordEdit: null,
			forgetPasswordEditConfirm: null,
			forgetPasswordToken: 'rdCZwppz8ELKIKW06ufVBPm0cmR6cm',
		};
	},
	methods: {
		register() {
			if (this.signupPassword !== this.signupConfirmPassword) {
				this.$store.commit(
					'snackbar/showErrorSnackbar',
					this.$t('login.edit.unmatchpassword'),
				);
				return;
			}
			this.loading = true;
			this.$store
				.dispatch('login/register', {
					name: this.signupName,
					userName: this.signupUserName,
					email: this.signupEmail,
					password: this.signupPassword,
					confirmPassword: this.signupConfirmPassword,
				})
				.then((res) => {
					this.$router.push({ name: 'home' });
				})
				.catch((err) => {
					this.$store.commit(
						'snackbar/showErrorSnackbar',
						err.response.data,
					);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		login() {
			this.loading = true;
			this.$store
				.dispatch('login/authentication', {
					userName: this.loginUserName,
					password: this.loginPassword,
				})
				.then((res) => {
					if (this.$fromEdunix) {
						this.$router.push({ name: 'edunix' });
					} else {
						this.$router.push({ name: 'home' });
					}
				})
				.catch((err) => {
					this.$store.commit(
						'snackbar/showErrorSnackbar',
						err.response.data,
					);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		sendForgetPassword() {
			this.loading = true;
			this.$store
				.dispatch('login/forgetPassword', {
					email: this.forgetPasswordEmail,
				})
				.then((res) => {
					this.resetAll();
					this.isForgetPasswordCode = true;

					this.$router.replace({
						query: {
							request: new Date().getTime(),
						},
					});

					this.$store.commit(
						'snackbar/showSuccessSnackbar',
						this.$t('login.forget-password-form.success'),
					);
				})
				.catch((err) => {
					this.$store.commit('snackbar/showErrorSnackbar', err);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		sendForgetPasswordCode() {
			this.loading = true;
			this.$store
				.dispatch('login/sendCode', {
					code: this.forgetPasswordCode,
				})
				.then((res) => {
					this.resetAll();
					this.isForgetPasswordEdit = true;

					this.forgetPasswordToken = res.token;
				})
				.catch((err) => {
					this.$store.commit('snackbar/showErrorSnackbar', err);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		sendForgetPasswordEdit() {
			if (this.forgetPasswordEdit !== this.forgetPasswordEditConfirm) {
				this.$store.commit(
					'snackbar/showErrorSnackbar',
					this.$t('login.edit.unmatchpassword'),
				);
				return;
			}
			this.loading = true;
			this.$store
				.dispatch('login/resetPassword', {
					token: this.forgetPasswordToken,
					password: this.forgetPasswordEdit,
					passwordConfirmation: this.forgetPasswordEditConfirm,
				})
				.then(() => {
					this.resetAll();
					this.isLogin = true;

					this.$store.commit(
						'snackbar/showSuccessSnackbar',
						this.$t('login.edit.success'),
					);
				})
				.catch((err) => {
					this.$store.commit('snackbar/showErrorSnackbar', err);
				});
		},

		registerWithGoogle() {
			this.loadingGoogle = true;
			this.$store
				.dispatch('login/registerWithGoogle')
				.then((res) => {
					window.open(res, '_self');
				})
				.finally(() => {
					this.loadingGoogle = false;
				});
		},

		resetAll() {
			this.loading = false;
			this.isLogin = false;
			this.isSignup = false;
			this.isForgetPassword = false;
			this.isForgetPasswordCode = false;
			this.isForgetPasswordEdit = false;
		},

		showPassword(e) {
			if (e.target.dataset.password === 'true') {
				e.target.nextSibling.type = 'text';
				e.target.dataset.password = 'false';
			} else {
				e.target.nextSibling.type = 'password';
				e.target.dataset.password = 'true';
			}
		},
	},

	mounted() {
		if (this.$route.query.request) {
			const thereIsTime =
				new Date().getTime() - Number(this.$route.query.request) <
				1000 * 60 * 60;
			if (thereIsTime) {
				this.isForgetPasswordCode = true;
				return;
			} else {
				this.$router.replace({
					query: {},
				});
			}
		}
		this.isLogin = true;
	},

	metaInfo() {
		return {
			title: this.$i18n.t('title-page.login'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>

<style lang="scss" scoped>
.card-container {
	padding: 2rem 4rem;
	border-radius: 15px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	background-color: white;
	max-width: 500px;
	margin: auto;
	> div {
		animation: card-enter 0.4s forwards;
		@keyframes card-enter {
			from {
				opacity: 0;
				transform: scale(0.9);
			}
			to {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
	@media (max-width: 576px) {
		padding: 2rem;
	}
}
input {
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0.3em 1em;
	width: 100%;
	&:valid {
		border: 1px solid #333;
	}
}
label {
	display: block;
	text-align: start;
	text-transform: capitalize;
	margin-bottom: 0.3rem;
}
.change-form-btn {
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
	font-weight: bold;
}
.footer-text {
	font-size: 0.8rem;
}
.clickable {
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		text-decoration: underline;
	}
}
.google-btn {
	cursor: pointer;
	display: block;
	padding: 10px 1rem;
	margin: auto;
	background-color: #234487;
	color: white;
	border-radius: 3rem;
	box-shadow: 0 0 5px #0002;
	text-align: center;
	width: max-content;
	min-width: 270px;
	transition: 0.3s;
	&:disabled {
		opacity: 0.6;
	}
	&:hover:not(:disabled) {
		box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
			rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
			rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
			rgba(0, 0, 0, 0.07) 0px 32px 64px;
		transform: scale(1.03);
	}
}

form > div {
	position: relative;
	[data-password]::after {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		left: 0;
	}
	[data-password='true']::after {
		content: '\f070';
	}
	[data-password='false']::after {
		content: '\f06e';
	}
}
</style>

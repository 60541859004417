<template>
	<router-link
		class="news-card"
		:to="{ name: 'newsView', params: { slug: news.slug } }">
		<div class="image image--cover">
			<img
				:src="news.primaryImage.url"
				:alt="news.title + ' image'"
				width="115px"
				class="news-card__image" />
			<div class="news-card__text pb-2">
				<p class="news-card__text-title mb-0">{{ news.title }}</p>
				<p class="news-card__text-summary mb-1">
					{{ news.summary.length > 35 ? news.summary.slice(0, 35) + '...' : news.summary }}
				</p>
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	props: {
		news: Object,
	},
};
</script>

<style lang="scss" scoped>
.news-card {
	border-radius: 15px;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	transition: 0.3s;
	aspect-ratio: 1 / 1;
	&:hover {
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	}
	.image {
		width: 100%;
		height: 100%;
	}
	&__image {
		width: 100%;
		height: 100%;
	}
	&__text {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		padding: 10px;
		padding-top: 2rem;
		background-image: linear-gradient(to top, #000b 40%, #0000);
		z-index: 2;
		&-title,
		&-summary {
			font-size: 1.1rem;
			height: 1.5em;
			overflow: hidden;
			color: white;
		}
		&-summary {
			font-size: 0.8rem;
			color: #ccc;
		}
		a {
			font-size: 0.8rem;
		}
	}
}
</style>

<template>
	<div class="px-3">
		<div
			v-if="!success"
			class="bg-grey w-100 d-flex align-items-center justify-content-center"
			style="height: 80vh">
			<div class="spinner-border"></div>
		</div>
		<div
			class="edunix-store d-block d-md-flex pt-3 pb-5"
			v-else>
			<!-- categories list -->
			<list-categories
				@change="categoriesChanged"
				@search="search"
				class="mb-3"></list-categories>

			<div class="main-content">
				<!-- loader -->
				<div
					v-if="productLoading"
					class="bg-grey w-100 d-flex align-items-center justify-content-center"
					style="height: 50vh">
					<div class="spinner-border"></div>
				</div>

				<!-- main content -->
				<div v-else>
					<!-- all products aka select all products -->
					<div v-if="allProducts">
						<!-- for no products -->
						<p
							v-if="!products.length"
							class="fs-4 fw-light text-muted text-uppercase my-4">
							{{ $t('EDUNIX-STORE.no-products') }}
						</p>

						<!-- the products -->
						<div class="row mx-0 gy-2 gx-2">
							<div
								v-for="(product, i) in products"
								:key="i"
								class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<card-product :product="product">
								</card-product>
							</div>
						</div>
					</div>

					<!-- when category had selected -->
					<div v-else>
						<!--The Most Wanted -->
						<section>
							<!-- title and show more -->
							<div
								class="d-flex justify-content-between align-items-center mb-3">
								<h3 class="text--color text-capitalize">
									{{ $t('EDUNIX-STORE.most') }}
								</h3>
								<button
									@click="
										allProducts = true;
										products = mostWantedProduct;
									"
									v-if="products.length > 8"
									class="button-store">
									{{ $t('EDUNIX-STORE.button') }}
								</button>
							</div>

							<!-- for no products -->
							<p
								v-if="!mostWantedProduct.length"
								class="fs-4 fw-light text-muted text-uppercase my-4">
								{{ $t('EDUNIX-STORE.no-products') }}
							</p>

							<!-- the products -->
							<div class="row mx-0 gy-2 gx-2">
								<div
									v-for="(
										product, i
									) in mostWantedProduct.slice(0, 8)"
									:key="i"
									class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
									:class="{
										'd-none d-xl-block': i > 5,
									}">
									<card-product :product="product">
									</card-product>
								</div>
							</div>
						</section>

						<!--the most distinguished -->
						<section class="mt-5">
							<!-- title and show more -->
							<div
								class="d-flex justify-content-between align-items-center mb-3">
								<h3 class="text--color text-capitalize">
									{{ $t('EDUNIX-STORE.Distinguished') }}
								</h3>
								<button
									v-if="products.length > 8"
									class="button-store">
									{{ $t('EDUNIX-STORE.button') }}
								</button>
							</div>

							<!-- for no products -->
							<p
								v-if="!mostDistinguishedProduct.length"
								class="fs-4 fw-light text-muted text-uppercase my-4">
								{{ $t('EDUNIX-STORE.no-products') }}
							</p>

							<!-- the products -->
							<div class="row mx-0 gy-2 gx-2">
								<div
									v-for="(
										product, i
									) in mostDistinguishedProduct.slice(0, 8)"
									:key="i"
									class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
									:class="{
										'd-none d-xl-block': i > 5,
									}">
									<card-product :product="product">
									</card-product>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CardProduct from '../components/CardProduct.vue';
import ListCategories from '../components/ListCategories.vue';
import { mapState } from 'vuex';

export default {
	components: {
		CardProduct,
		ListCategories,
	},

	data() {
		return {
			success: false,
			productLoading: false,
			products: [], // products after filter by categories of all products

			allProducts: true, // change ui when select all products
		};
	},

	computed: {
		ordersProduct() {
			return [...this.orders.map((o) => [...o.cart.products])]
				.flat()
				.map((p) => p.product);
		},
		mostWantedProduct() {
			// eslint-disable-next-line
			return this.products.sort((a, b) => b.view - a.view);
		},
		mostDistinguishedProduct() {
			return this.products.filter((e) => e.isFeatured);
		},
		...mapState({
			categories: (state) => state.categories.categories,
			orders: (state) => state.orders.orders,
		}),
	},

	methods: {
		search(text) {
			this.productLoading = true;
			this.$store.dispatch('products/search', { text }).then((res) => {
				this.products = res;
				this.productLoading = false;
				this.allProducts = true;
			});
		},

		// category change from the list
		categoriesChanged(category) {
			this.productLoading = true;
			// list select all
			if (!category) {
				this.$store
					.dispatch('products/fetchAllProducts')
					.then((res) => {
						this.products = res;
						this.productLoading = false;
						this.allProducts = true;
					});
				return;
			}
			//list select orders
			if (category === 'orders') {
				this.products = this.ordersProduct;
				this.productLoading = false;
				this.allProducts = true;
				return;
			}
			// list select a category
			this.$store
				.dispatch('categories/fetchBySlug', {
					slug: category,
				})
				.then((res) => {
					this.productLoading = false;
					this.products = this.getProductsByCategory(res);
					this.allProducts = false;
				});
		},

		// return the category's products and it's children's products
		getProductsByCategory(category) {
			const allProducts = category.categoriesProduct;

			category.children.forEach((e) => {
				allProducts.push(...e.categoriesProduct);
			});
			return allProducts;
		},

		// fetch
		fetch() {
			this.success = false;

			Promise.all([
				this.$store.dispatch('categories/fetchTreeCategories'),
				this.$store.dispatch('orders/fetchAllOrders'),
			]).then(() => {
				this.success = true;
			});
		},
	},

	created() {
		this.fetch();
	},

	metaInfo() {
		return {
			title: this.$i18n.t('title-page.store'),
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	width: calc(100% - 300px);
	@media (max-width: 1200px) {
		width: calc(100% - 250px);
	}
	@media (max-width: 768px) {
		width: 100%;
	}
}
</style>

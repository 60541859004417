import * as types from '../mutation-types';
export default {
	namespaced: true,
	state: {
		categories: [],
		categoriesTree: [],
	},
	actions: {
		fetchAllCategories({ commit, state }) {
			return state.categories.length
				? state.categories
				: window.axios.get('/categories').then((response) => {
					commit(types.CATEGORIES, response.data);
					return response.data;
				});
		},
		fetchBySlug({ commit }, { slug }) {
			return window.axios.get(`/categories/${slug}`).then((response) => {
				return response.data;
			});
		},
		fetchTreeCategories({ commit, state }) {
			return state.categoriesTree.length
				? state.categoriesTree
				: window.axios.get('/categories/hierarchy').then((response) => {
					commit(types.CATEGORIES_TREE, response.data);
					return response.data;
				});
		},
	},
	mutations: {
		[types.CATEGORIES](state, categories) {
			state.categories = categories;
		},
		[types.CATEGORIES_TREE](state, categories) {
			state.categoriesTree = categories;
		},
	},
	getters: {
		getCategoryById: (state) => (slug) =>
			state.categories.find((c) => c.slug === slug),
	},
};

<template>
	<div
		v-if="!success"
		class="bg-grey w-100 d-flex align-items-center justify-content-center"
		style="height: 100vh">
		<div class="spinner-border"></div>
	</div>
	<div v-else>
		<div
			class="container py-4"
			v-if="typeof faq === 'object' && faq.length">
			<div class="d-block d-md-flex">
				<div
					class="categories-container"
					v-if="false">
					<ul class="categorie-list">
						<li
							class="categorie-item selected"
							@click="faqCategorieId = 1">
							category
						</li>
					</ul>
				</div>
				<div class="question-list">
					<div
						class="question-item"
						v-for="(item, i) in faq"
						:key="i">
						<div
							@click="showAnswerId = showAnswerId === i ? null : i"
							class="question-item--question cp"
							:class="{ show: showAnswerId === i }">
							<span>
								{{ item.summary }}
							</span>
						</div>
						<div
							class="question-item--answer"
							:class="{ show: showAnswerId === i }">
							<div v-html="item.content"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="button-orange mx-auto"
			@click="showForm = true"
			v-if="!showForm">
			إسأل سؤال
		</div>
		<div
			class="container mb-5 ask-container"
			:class="{ show: showForm }">
			<h2 class="fw-bold h4">إسأل سؤال</h2>
			<get-copy-form></get-copy-form>
		</div>
	</div>
</template>

<script>
import GetCopyForm from '@/components/GetCopyForm.vue';
import { mapState } from 'vuex';
export default {
	name: 'Faq',
	data() {
		return {
			success: false,
			showAnswerId: null,

			showForm: false,
		};
	},
	components: {
		GetCopyForm,
	},
	computed: {
		...mapState({
			faq: (state) => state.faq.faq,
		}),
	},
	methods: {
		fetch() {
			this.success = false;
			this.$store.dispatch('faq/fetchAllFaq').then(() => {
				this.success = true;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
@import '@/scss/style.scss';
.container {
	max-width: 900px;
	margin: auto;
}
.categories-container {
	.categorie-list {
		list-style: none;
		padding: 0;
		.categorie-item {
			cursor: pointer;
			border: 1px solid #ccc;
			border-radius: 15px;
			padding: 0.5rem 1.5rem;
			margin-bottom: 5px;
			text-transform: capitalize;
			&.selected {
				background-color: $alt-color;
				color: white;
			}
		}
	}
}
.question-list {
	flex: 1;
	padding: 0 10px;
	[dir='rtl'] {
		margin-right: 10px;
	}
	[dir='ltr'] {
		margin-left: 10px;
	}
}
.question-item {
	background-color: white;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	margin-bottom: 5px;
	&--question {
		padding: 1rem 1.5rem;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&::after {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			border-bottom: 2px solid #999;
			border-right: 2px solid #999;
			transform: rotate(45deg);
			transition: 0.3s;
		}
		&.show {
			&::after {
				transform: rotate(225deg);
			}
		}
	}
	&--answer {
		padding: 0 1.5rem;
		overflow: hidden;
		border-top: 1px solid transparent;
		max-height: 0;
		transition: max-height 0.4s linear, padding 0.1s 0.4s linear, border-top 0s 0.4s;
		&.show {
			padding: 10px;
			border-top: 1px solid #ccc;
			overflow: auto;
			max-height: 200px;
			transition: max-height 0.4s linear, padding-top 0.1s linear;
		}
	}
	position: relative;
}
.ask-container {
	max-height: 0;
	overflow: hidden;
	transition: 1s;
	&.show {
		max-height: 600px;
	}
}
</style>

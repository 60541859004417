import * as types from '../mutation-types';

export default {
	namespaced: true,

	state: {
		loged: false,
		user: null,
		self: null,
	},
	actions: {
		register(
			{ dispatch },
			{ name, userName, email, password, confirmPassword },
		) {
			return window.axios
				.post('/register', {
					fullName: name,
					userName: userName,
					email: email,
					password: password,
					passwordConfirmation: confirmPassword,
					address: '',
					phone: '',
				})
				.then((res) => {
					dispatch('login', { user: res.data });
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},

		registerWithGoogle() {
			return window.axios
				.get('/authentication/google/redirect')
				.then((res) => {
					return res.data;
				});
		},

		authentication({ dispatch }, { userName, password }) {
			return window.axios
				.post('/authentication', {
					userName: userName,
					password: password,
				})
				.then((res) => {
					dispatch('login', { user: res.data });
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},

		loginWithCode({ dispatch }, { code }) {
			return window.axios
				.post('/authentication', { code: code })
				.then((res) => {
					dispatch('login', { user: res.data });
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},

		loginWithGoogle({ dispatch }, { loginCode }) {
			return window.axios
				.get(`/auth/google/callback`, { params: { code: loginCode } })
				.then((res) => {
					dispatch('login', { user: res.data });
					return res.data;
				});
		},

		forgetPassword({ dispatch }, { email }) {
			return window.axios
				.post('/authentication/password/email', { email: email })
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},

		sendCode({ commit }, { code }) {
			return window.axios
				.post('/authentication/password/code/check', { token: code })
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},

		resetPassword({ dispatch }, { token, password, passwordConfirmation }) {
			return window.axios
				.post('/authentication/password/reset', {
					token,
					password,
					passwordConfirmation,
				})
				.then((res) => {
					dispatch('login', { user: res.data });
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},

		login({ commit, dispatch }, { user }) {
			console.log('logining...');

			commit('changeLoged', true);
			commit('changeUser', user); // put the user info in state.user
			// add the token to axios header
			window.axios.defaults.headers.common.Authorization =
				'Bearer ' + user.access_token;

			// add the token to local storage
			localStorage.setItem(
				'edunix-website-token',
				JSON.stringify({ user }),
			);
			return Promise.all([
				dispatch('cart/getCart', null, { root: true }),
				dispatch('getSelf'),
			]).catch((err) => {
				throw err;
			});
		},

		logout({ commit }) {
			console.log('logouting...');
			commit('changeLoged', false);
			commit('changeUser', null); // reset the user info in state.user

			// delete the token to axios header
			delete window.axios.defaults.headers.common.Authorization;

			// delete the token from local storage
			localStorage.removeItem('edunix-website-token');
		},

		getSelf({ commit }) {
			return window.axios.get('/users/self').then((res) => {
				commit('self', res.data);
				return res.data;
			});
		},
	},

	mutations: {
		changeLoged(state, value) {
			state.loged = value;
		},
		changeUser(state, user) {
			state.user = user;
		},
		self(state, self) {
			state.self = self;
		},
	},
};

<template>
	<div class="advantage-card">
		<div class="image">
			<img
				:src="data.primaryImage.url"
				alt="features image" />
		</div>
		<div class="title">
			{{ data.title }}
		</div>
		<div class="content">
			<div v-html="data.content"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AdvatageCard',
	props: ['data'],
	data() {
		return {
			openCard: false,
		};
	},
};
</script>

.
<style lang="scss" scoped>
.advantage-card {
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	background-color: #fefefe;
	padding: 1rem;
	.image {
		margin: auto;
		margin-bottom: 0.5rem;
		width: 8rem;
		height: 8rem;
	}
	.title {
		font-weight: bold;
	}
}
</style>
<style>
.advantage-card .content * {
	font-size: 0.8rem;
	margin-bottom: 0;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import categories from './modules/categories';
import products from './modules/products';
import news from './modules/news';
import services from './modules/services';
import testimonial from './modules/testimonial';
import login from './modules/login';
import cart from './modules/cart';
import feedback from './modules/feedback';
import orders from './modules/orders';
import snackbar from './modules/snackbar';
import values from './modules/values';
import aboutus from './modules/aboutus';
import contacts from './modules/contacts';
import albums from './modules/albums';
import faq from './modules/faq';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		categories,
		products,
		news,
		services,
		testimonial,
		login,
		cart,
		feedback,
		orders,
		snackbar,
		values,
		aboutus,
		contacts,
		albums,
		faq,
	},
});

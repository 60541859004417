<template>
	<div
		v-if="!success"
		class="bg-grey w-100 d-flex align-items-center justify-content-center"
		style="height: 100vh"
	>
		<div class="spinner-border"></div>
	</div>
	<div v-else class="py-5 px-2 px-sm-5">
		<div class="d-flex justify-content-center align-center flex-wrap">
			<CardService
				v-for="(service, j) in services"
				:key="j"
				:service="service"
				class="d-flex mx-2 mb-3"
			>
			</CardService>
		</div>
	</div>
</template>

<script>
import CardService from "@/components/CardService.vue";
import { mapState } from "vuex";
export default {
	name: "AllServices",
	components: { CardService },
	data() {
		return {
			success: false,
		};
	},
	methods: {
		fetch() {
			this.success = false;
			this.$store.dispatch("services/fetchAllServices").then(() => {
				this.success = true;
			});
		},
	},
	computed: {
		...mapState({
			services: (state) => state.services.services,
		}),
	},
	created() {
		this.fetch();
	},
};
</script>
export default {
    namespaced: true,
    state: {
        massage: null,
        isError: true,
    },
    mutations: {
        showSuccessSnackbar(state, massage) {
            state.isError = false
            state.massage = massage
            setTimeout(() => {
                state.massage = null;
            }, 5000);
        },
        showErrorSnackbar(state, massage) {
            state.isError = true
            state.massage = massage
            setTimeout(() => {
                state.massage = null;
            }, 5000);
        },
    }
}
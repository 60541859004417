<template>
	<router-link :to="{ name: 'features', params: { slug: product.slug } }">
		<div class="card-product">
			<div
				class="line-new"
				v-if="isNew">
				{{ $t('EDUNIX-STORE.card.new') }}
			</div>
			<div class="card-product__image">
				<div class="image image--cover">
					<img
						:src="product.primaryImage.url"
						:alt="product.name + ' image'" />
				</div>
				<div class="card-product__category">{{ category }}</div>
				<div
					class="card-product__added"
					v-if="isOrdered">
					{{ $t('EDUNIX-STORE.card.added') }} &check;
				</div>
			</div>
			<div class="card-product__text">
				<div>
					{{ product.name }}
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		product: { type: Object },
	},
	computed: {
		isNew() {
			return (
				new Date().getTime() -
					new Date(this.product.updatedAt).getTime() <
				1000 * 60 * 60 * 24 * 3
			);
		},
		isOrdered() {
			return !![...this.orders.map((o) => [...o.cart.products])]
				.flat()
				.find((e) => e.product.id == this.product.id);
		},
		category() {
			let name = '';
			for (let i = 0; i < this.categoriesTree.length; i++) {
				const e = this.categoriesTree[i];
				if (name) break;
				if (e.id === this.product.categoriesIds[0]) name = e.name;
				if (e.children.length) {
					for (let j = 0; j < e.children.length; j++) {
						const ee = e.children[j];
						if (name) break;
						if (ee.id === this.product.categoriesIds[0])
							name = ee.name;
					}
				}
			}
			return name;
		},
		...mapState({
			categoriesTree: (state) => state.categories.categoriesTree,
			orders: (state) => state.orders.orders,
		}),
	},
};
</script>
<style lang="scss" scoped>
@import '@/scss/style.scss';

.card-product {
	border-radius: 20px;
	overflow: hidden;
	background-color: white;
	position: relative;
	height: 270px;
	@media (max-width: 576px) {
		height: 80vw;
	}
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	transition: 300ms;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 12px;
	}

	&__image {
		position: relative;
		height: calc(100% - 4.5rem);
		.image {
			height: 100%;
			position: relative;
		}
	}

	&__text {
		padding: 0.5rem 0.5rem 1rem;
		> div {
			font-size: 1rem;
			line-height: 1.5rem;
			height: 3rem;
			overflow: hidden;
		}
	}

	&__category {
		position: absolute;
		bottom: 10px;
		right: 10px;
		background: $sec-color;
		color: white;
		font-size: 13px;
		font-weight: 200;
		border-radius: 20px;
		padding: 0.2em 0.7em;
	}

	&__added {
		position: absolute;
		bottom: 10px;
		left: 10px;
		background: #218a29;
		color: white;
		font-size: 13px;
		font-weight: 200;
		border-radius: 20px;
		padding: 0.2em 0.7em;
	}
}

.line-new {
	background-color: #2db962;
	color: white;
	font-size: 1rem;
	transform: rotate(40deg) translateX(25%) translateY(-50%);
	position: absolute;
	top: 0;
	right: 0;
	padding: 2px 60px;
	z-index: 1;
}
</style>

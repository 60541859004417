import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		testimonial: [],
	},
	actions: {
		fetchAllTestimonial({ commit }) {
			return window.axios
				.get('/custom-services', {
					params: {
						type: 2,
						typeName: 'testimonial',
					},
				})
				.then((response) => {
					commit(types.TESTIMONIAL, response.data);
					return response.data;
				})
				.catch(() => {});
		},
	},
	mutations: {
		[types.TESTIMONIAL](state, testimonial) {
			state.testimonial = testimonial;
		},
	},
};

<template>
	<div
		v-if="!success"
		class="bg-grey w-100 d-flex align-items-center justify-content-center"
		style="height: 100vh">
		<div class="spinner-border"></div>
	</div>
	<div
		v-else
		class="container py-5">
		<section class="main-content">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-7">
					<div class="main-content__date">
						{{
							new Date(newsIndex.date).toLocaleString('sp-Br', {
								dateStyle: 'short',
							})
						}}
					</div>
					<h1 class="main-content__title alt-text text-capitalize">
						{{ newsIndex.title }}
					</h1>
					<div
						v-html="newsIndex.content"
						class="main-content__text"></div>
				</div>
				<div class="col-12 col-md-6 col-lg-5">
					<div class="main-content__image">
						<img
							:src="newsIndex.primaryImage.url"
							:alt="newsIndex.title + ' image'" />
					</div>
				</div>
			</div>
		</section>
		<section
			class="more-images mt-5"
			v-if="newsIndex.images">
			<h2 class="more-images__title alt-text mb-3 text-capitalize">
				{{ $t('news-page.more-images') }}
			</h2>
			<div class="d-flex flex-wrap">
				<div
					@click="showLightbox(i)"
					class="more-images__item"
					v-for="(image, i) in newsIndex.images"
					:key="image.id">
					<img
						:src="image.url"
						:alt="`${newsIndex.title}-${i}`" />
				</div>
			</div>
		</section>
		<section
			class="more-news mt-5"
			v-if="news.length > 1">
			<h2 class="more-news__title alt-text mb-3 text-capitalize">
				{{ $t('news-page.more-news') }}
			</h2>
			<div class="more-news__grid">
				<news-component
					v-for="n in news.filter((e) => e.slug !== $route.params.slug).slice(0, 4)"
					:key="n.id"
					:news="n"></news-component>
			</div>
		</section>

		<vue-easy-lightbox
			:visible="lightboxVisible"
			:imgs="newsIndex.images.map((e) => e.url)"
			:index="imageIndex"
			@hide="hideLightbox"></vue-easy-lightbox>
	</div>
</template>

<script>
import NewsComponent from '@/components/NewsComponent.vue';
import { mapState, mapGetters } from 'vuex';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
	name: 'NewsView',
	components: { NewsComponent, VueEasyLightbox },
	data() {
		return {
			success: false,
			newsIndex: {},

			lightboxVisible: false,
			imageIndex: 0,
		};
	},
	watch: {
		'$route.params.slug'() {
			this.fetch();
		},
	},
	methods: {
		showLightbox(i) {
			this.lightboxVisible = true;
			this.imageIndex = i;
		},
		hideLightbox() {
			this.lightboxVisible = false;
		},
		fetch() {
			this.success = false;
			this.$store.dispatch('news/fetchAllNews').then((res) => {
				this.newsIndex = this.getNewsBySlug(this.$route.params.slug);
				this.success = true;
			});
		},
	},
	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
		...mapGetters({
			getNewsBySlug: 'news/getNewsBySlug',
		}),
	},
	created() {
		this.fetch();
	},

	metaInfo() {
		return {
			title: this.newsIndex.title,
			titleTemplate: '%s | ' + this.$i18n.t('main-title'),
		};
	},
};
</script>

<style lang="scss" scoped>
* {
	text-align: start;
}
.main-content {
	&__date {
		font-size: 0.9rem;
		line-height: 1em;
		color: #999;
	}
	&__image {
		width: 100%;
		height: 500px;
		@media (max-width: 1200px) {
			height: 400px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 15px;
		}
	}
}
.more-images {
	&__item {
		cursor: pointer;
		width: 130px;
		height: 130px;
		border-radius: 15px;
		overflow: hidden;
		margin-bottom: 10px;
		[dir='ltr'] & {
			margin-right: 10px;
		}
		[dir='rtl'] & {
			margin-left: 10px;
		}
		@media (max-width: 576px) {
			width: calc(45vw - 10px);
			height: calc(45vw - 10px);
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.more-news {
	&__grid {
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(4, 1fr);
		@media (max-width: 992px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (max-width: 576px) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
</style>

import * as types from '../mutation-types';

export default {
    namespaced: true,

    state: {
        contacts: null,
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/contacts').then((res) => {
                commit(types.CONTACTS, res.data);
                return res.data;
            });
        },
    },

    mutations: {
        [types.CONTACTS](state, contacts) {
            state.contacts = contacts[0];
        },
    },
};

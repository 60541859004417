<template>
	<div>
		<div
			class="edunix-store-list px-3 py-4 mx-lg-3 rounded-4"
			:class="{ 'fix-list': fixList }"
			ref="categoriesList">
			<!-- top part -->
			<div
				class="d-flex justify-content-between justify-content-md-center">
				<!-- hamburger -->
				<div
					class="d-block d-md-none"
					ref="listToggle"
					@click="
						showList = !showList;
						showSearch = false;
					">
					<i class="fa fa-bars fs-5"></i>
				</div>

				<!-- title -->
				<p class="main-text text-center mb-0 mb-md-3">
					متجر إديونكس<span class="grey"> | </span>
					<span class="sec-text">Edunix Store</span>
				</p>

				<!-- search toggle -->
				<div
					class="d-block d-md-none"
					ref="searchToggle"
					@click="
						showList = false;
						showSearch = !showSearch;
					">
					<i class="fa fa-search fs-5"></i>
				</div>
			</div>

			<div class="category-list-container">
				<!-- search input -->
				<div
					class="category-list-container__search"
					:class="{ show: showSearch }">
					<input
						v-model="search"
						:placeholder="$t('EDUNIX-STORE.list.search')"
						class="search"
						@keyup.enter="startSearch()" />
					<div
						class="search__btn-container"
						:class="{ show: search.length }">
						<button
							class="search__btn"
							@click="startSearch()">
							{{ $t('EDUNIX-STORE.list.search-btn') }}
						</button>
					</div>
				</div>

				<!-- categories  -->
				<div
					class="category-list-container__list"
					:class="{ show: showList }">
					<ul class="category-list">
						<!-- all categories -->
						<li
							:class="{
								selected:
									selectedCategory === null &&
									selectedOrders === false,
							}"
							@click="selectAll">
							<div class="category-list__item">
								{{ $t('EDUNIX-STORE.list.all') }}
							</div>
						</li>

						<!-- categories list -->
						<li
							v-for="category in categoriesTree"
							:class="{
								selected:
									category.slug == selectedParentCategorySlug,
							}"
							:key="category.id"
							:id="category.slug">
							<div
								class="category-list__item"
								@click="
									selectedParentCategorySlug === category.slug
										? (selectedParentCategorySlug = null)
										: chooseCategory(category)
								">
								{{ category.name }}
								<div
									class="category-list__arrow"
									v-if="category.children.length"></div>
							</div>

							<!-- sub categories -->
							<div
								v-if="
									selectedParentCategorySlug ===
										category.slug &&
									category.children.length
								">
								<ul class="sub-category-list">
									<li
										@click="selectCategory(category.slug)"
										:class="{
											selected:
												category.slug ===
												selectedCategory,
										}">
										{{ $t('EDUNIX-STORE.list.all') }}
									</li>
									<li
										v-for="subCategory in category.children"
										@click="
											selectCategory(subCategory.slug)
										"
										:class="{
											selected:
												subCategory.slug ===
												selectedCategory,
										}"
										:key="subCategory.id">
										{{ subCategory.name }}
									</li>
								</ul>
							</div>
						</li>

						<!-- orders -->
						<li
							:class="{
								selected: selectedOrders,
							}"
							@click="selectOrders">
							<div class="category-list__item">
								{{ $t('EDUNIX-STORE.list.order') }}
							</div>
						</li>
					</ul>
				</div>
			</div>
			<router-link
				v-if="$fromEdunix"
				:to="{ name: 'cart' }">
				<div class="button-store text-center mt-2">cart</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			search: '',
			categories: [],
			selectedCategory: null,
			selectedOrders: false,
			selectedParentCategorySlug: null,

			// used for mobile only
			showList: false,
			showSearch: false,
			fixList: false,

			// to clear events before destroy
			scrollEvent: null,
			clickEvent: null,
		};
	},

	computed: {
		...mapState({
			categoriesTree: (state) => state.categories.categoriesTree,
			products: (state) => state.products.products,
		}),
	},

	methods: {
		startSearch() {
			if (!this.search) {
				this.$emit('change');
				return;
			}
			this.showSearch = false;
			this.selectedOrders = false;
			this.selectedCategory = null;
			this.selectedParentCategorySlug = null;

			if (this.$route.name !== 'edunix') {
				this.$router.push({
					name: 'edunix',
					query: { search: this.search },
				});
			} else {
				this.$router.replace({
					query: {
						...this.$route.query,
						search: this.search,
					},
				});
				if (this.$route.query.category) {
					const query = { ...this.$route.query };
					delete query.category;
					this.$router.replace({ query });
				}
			}
			this.$emit('search', this.search);
		},

		selectAll() {
			this.selectedOrders = false;
			this.selectedCategory = null;
			this.selectedParentCategorySlug = null;
			this.$emit('change');
			this.routerToAll();
		},
		selectCategory(cateSlug) {
			this.selectedOrders = false;
			this.selectedCategory = cateSlug;
			this.$emit('change', cateSlug);
			this.routerOrChangeQuery(cateSlug);
		},
		selectOrders() {
			this.selectedOrders = true;
			this.selectedCategory = null;
			this.selectedParentCategorySlug = null;
			this.$emit('change', 'orders');
			this.routerToOrders();
		},

		chooseCategory(category) {
			if (!category.children.length) {
				this.selectCategory(category.slug);
			}
			this.selectedParentCategorySlug = category.slug;
		},

		routerToOrders() {
			if (this.$route.name !== 'edunix') {
				this.$router.push({
					name: 'edunix',
					query: { category: 'orders' },
				});
			} else {
				if (this.$route.query.category !== 'orders') {
					this.$router.replace({
						query: {
							...this.$route.query,
							category: 'orders',
						},
					});
				}

				if (this.$route.query.search) {
					const query = { ...this.$route.query };
					delete query.search;
					this.$router.replace({ query });
				}
			}
		},

		routerToAll() {
			if (this.$route.name !== 'edunix') {
				this.$router.push({
					name: 'edunix',
				});
			} else {
				if (this.$route.query.category) {
					const query = { ...this.$route.query };
					delete query.category;
					this.$router.replace({ query });
				}
				if (this.$route.query.search) {
					const query = { ...this.$route.query };
					delete query.search;
					this.$router.replace({ query });
				}
			}
		},
		routerOrChangeQuery(query) {
			if (this.$route.name !== 'edunix') {
				this.$router.push({
					name: 'edunix',
					query: { category: query },
				});
			} else {
				if (this.$route.query.category !== query) {
					this.$router.replace({
						query: {
							...this.$route.query,
							category: query,
						},
					});
				}
				if (this.$route.query.search) {
					const query = { ...this.$route.query };
					delete query.search;
					this.$router.replace({ query });
				}
			}
		},
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollEvent);
		window.removeEventListener('click', this.clickEvent);
	},

	created() {
		this.search = this.$route.query.search || '';
		//contain all the categories
		this.categories = [
			...this.categoriesTree,
			...this.categoriesTree.map((e) => e.children),
		].flat();

		if (this.$route.name !== 'edunix') return;

		// select the category from router.query
		if (this.$route.query.category === 'orders') {
			this.selectOrders();
			return;
		}
		if (this.$route.query.category) {
			this.selectCategory(this.$route.query.category);

			const cate = this.categories.find(
				(e) => e.slug === this.$route.query.category,
			);

			if (!cate.parentId) {
				this.selectedParentCategorySlug = this.$route.query.category;
			} else {
				this.selectedParentCategorySlug = this.categories.find(
					(e) => e.id === cate.parentId,
				).slug;
			}
			return;
		}
		this.selectAll();
	},

	mounted() {
		// fix the list to top when hit the top of screen
		const listTop = this.$refs.categoriesList.offsetTop;
		this.scrollEvent = (e) => {
			if (listTop > document.documentElement.scrollTop) {
				this.fixList = false;
			} else {
				this.fixList = true;
			}
		};
		window.addEventListener('scroll', this.scrollEvent);

		// add event to close list and search on click outside
		this.clickEvent = (e) => {
			if (this.$refs.categoriesList.contains(e.target)) return;
			this.showList = false;
			this.showSearch = false;
		};
		window.addEventListener('click', this.clickEvent);
	},
};
</script>

<style lang="scss" scoped>
@import '@/scss/style.scss';

.edunix-store-list {
	position: sticky;
	top: 20px;
	width: 300px;
	@media (max-width: 1200px) {
		width: 250px;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
	background: #f8f8f8;
	border-radius: 15px;
	@media only screen and (max-width: 840px) {
		width: 100%;
	}
}

.search {
	width: 100%;
	height: 40px;
	border: 1px solid #ccc;
	background-color: white;
	font-size: 0.8rem;
	border-radius: 10px;
	padding: 5px 10px;
	&__btn-container {
		max-height: 0;
		transition: 0.3s;
		overflow: hidden;
		&.show {
			max-height: 100px;
		}
	}
	&__btn {
		padding: 5px 10px;
		border: none;
		border-radius: 10px;
		background-color: $alt-color;
		color: white;
		width: max-content;
		margin-top: 5px;
	}
}

.category-list {
	list-style: none;
	padding: 0;
	margin-top: 20px;
	li {
		cursor: pointer;
		text-transform: capitalize;

		&.selected {
			color: $alt-color;
		}
		.category-list__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 7px 10px;
			border-radius: 5px;
			&:hover {
				background: #e5e5e5;
			}
		}
		.category-list__arrow {
			width: 6px;
			height: 6px;
			border-bottom: 2px solid #333;
			border-right: 2px solid #333;
			transition: 0.3s;
			transform: rotate(45deg);
		}
		&.selected {
			.category-list__arrow {
				transform: rotate(-135deg);
			}
		}
	}
}
.sub-category-list {
	list-style: none;
	border-top: 1px solid #999;
	border-bottom: 1px solid #999;
	padding: 5px 0px;
	animation: sub-list-enter 500ms forwards;
	@keyframes sub-list-enter {
		from {
			max-height: 0;
		}
		to {
			max-height: 1000px;
		}
	}
	li {
		color: black;
		cursor: pointer;
		padding: 5px 25px;
		text-transform: capitalize;
		border-radius: 5px;
		&:hover {
			background: #ddd;
		}
		li.selected {
			color: $alt-color;
		}
	}
}
@media (max-width: 768px) {
	.edunix-store-list {
		position: static;
	}
	.list-replacer {
		height: 72px;
	}
	.fix-list {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
		border-top-right-radius: 0 !important;
		border-top-left-radius: 0 !important;
		animation: fix-list-enter 0.2s forwards;
		@keyframes fix-list-enter {
			from {
				top: -100%;
			}
			to {
				top: 0;
			}
		}
	}
	.category-list-container {
		position: relative;
		&__search,
		&__list {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			background: #f8f8f8;
			border-radius: 20px;
			box-shadow: 0 0 15px #0002;
			width: 100%;
			padding: 1rem;
			z-index: 10;
			transition: 0.3s;
			&.show {
				transform: translate(0);
				opacity: 1;
			}
		}
		&__search {
			transform: translateY(-500px);
			opacity: 0;

			display: flex;
			.search {
				min-width: 0;
				width: initial;
				flex: 1;
			}
			.search__btn-container {
				max-height: unset;
				max-width: 0;
				[dir='ltr'] & {
					margin-left: 10px;
				}
				[dir='rtl'] & {
					margin-right: 10px;
				}
				&.show {
					max-width: 200px;
				}
				.search__btn {
					padding: 8px 12px;
					margin-top: 0;
					border: none;
				}
			}
		}
		&__list {
			transform: translateY(-500px);
			opacity: 0;
			&.show {
				left: 0;
			}
		}
	}
}
</style>
